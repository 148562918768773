import "./App.scss"
import { useContext, useEffect, useState } from "react"
import { NavLink, Outlet, useNavigate, useSearchParams } from "react-router-dom"
import { NavItem, NavLabel, UserMenu, Button } from "@fsg/gui-bits"
import { ReactComponent as Opportunities } from "../assets/opportunities.svg"
import { ReactComponent as Logo } from "../assets/logo.svg"
import { ReactComponent as Menu } from "../assets/menu.svg"
import { ReactComponent as Refresh } from "../assets/rotate.svg"
import { routes } from "../Router/routes"
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks"
import {
  fetchJobs,
  getActiveJobNumber,
  clearActiveJobNumber,
  setJobSearchQuery,
  setActiveJobNumber,
} from "../Features/JobSelect/jobSearchSlice"
import { fetchJobRisk } from "../Features/JobRisk/jobRiskSlice"
import runReports from "../helpers/reports/runReports"
import { AuthContext } from "@fsg/apps-api-client-react"
import {
  clearReports,
  getReportsData,
} from "../Features/JobReports/jobReportsSlice"
import { apiClient } from "../api"
import LoginPage from "../Pages/Auth/Login/LoginPage"
import LoadingPage from "../Pages/Auth/Loading/LoadingPage"

const AppAuthWrapper = () => {
  const { loading: authIsLoading, isAuthenticated } = useContext(AuthContext)

  if (authIsLoading) return <LoadingPage />

  if (!isAuthenticated) return <LoginPage />

  return <App />
}

const routeToReportMap: { [key: string]: string } = {
  "job-summary": "summary-budget-ccdetail",
  budgets: "budgets",
  porecap: "porecap",
  costdetails: "costdetails",
  "manpower-forecast": "man_fore-team",
  "manpower-team": "man_fore-team",
  invoices: "invoices",
  sov: "sov",
  cashflow: "cashflow",
  accthist: "accthist",
  "payroll-history": "payroll",
}

function App() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const activeJobNumber = useAppSelector(getActiveJobNumber)
  const jobNumberSearchParam = searchParams.get("jobno")
  const { user } = useContext(AuthContext)
  const reports = useAppSelector(getReportsData)

  useEffect(() => {
    dispatch(fetchJobs())
  }, [dispatch])

  useEffect(() => {
    setMobileNavOpen(false)

    dispatch(
      fetchJobRisk()
    )

    if (activeJobNumber) {
      runReports(activeJobNumber)
    }
  }, [dispatch, activeJobNumber])

  useEffect(() => {
    if (jobNumberSearchParam) {
      dispatch(setJobSearchQuery(jobNumberSearchParam))
      dispatch(setActiveJobNumber(jobNumberSearchParam))
    }
  }, [dispatch, jobNumberSearchParam])

  const refreshJobData = (jobNumber: string) => {
    runReports(jobNumber)
  }

  const clearJobSelection = () => {
    setJobSearchQuery("")
    dispatch(clearActiveJobNumber())
    dispatch(clearReports())
    navigate("/")
  }

  const getReportStatus = (routePath: string) => {
    const report = routeToReportMap[routePath]
    if (report === undefined) return undefined
    return reports[report].status
  }

  return (
    <div className="app-wrapper">
      <div className={`navigation ${mobileNavOpen ? `mobile-open` : ``}`}>
        <NavLink className="logo" to="/">
          <Logo />
        </NavLink>
        <div className="navigation-links">
          {routes.map((section, index) => (
            <>
              <NavLabel
                icon={section.icon || Opportunities}
                className={`nav-label ${!activeJobNumber ? `blurred` : ``}`}
              >
                {section.name}
              </NavLabel>
              {section.routes.map((route, index) => {
                if (!route.nav) return null

                const reportStatus = getReportStatus(route.path)
                const notAReport = reportStatus === undefined

                if (notAReport)
                  return (
                    <NavItem
                      key={index}
                      className={`nav-item ${
                        !activeJobNumber ? `blurred` : ``
                      }`}
                      to={`${route.path}?jobno=${activeJobNumber}`}
                    >
                      {route.niceText}
                    </NavItem>
                  )

                return (
                  <NavItem
                    key={index}
                    className={`nav-item ${
                      !activeJobNumber ||
                      reportStatus === "idle" ||
                      reportStatus === "pending"
                        ? `blurred`
                        : ``
                    }`}
                    to={`${route.path}?jobno=${activeJobNumber}`}
                  >
                    {route.niceText}
                  </NavItem>
                )
              })}
            </>
          ))}
        </div>

        <div className="spacer" />

        <div className="actions">
          <Button
            buttonType="secondary"
            withIcon
            disabled={!activeJobNumber}
            className={`button-refresh ${!activeJobNumber && "disabled"}`}
            onClick={
              activeJobNumber
                ? () => refreshJobData(activeJobNumber)
                : undefined
            }
          >
            <Refresh /> Refresh job data
          </Button>
          <Button
            buttonType="secondary"
            size="small"
            className={`button-clear ${!activeJobNumber && "disabled"}`}
            disabled={!activeJobNumber}
            onClick={clearJobSelection}
          >
            Clear job selection
          </Button>
        </div>

        <div className="spacer" />

        <UserMenu
          className="user"
          name={user?.name}
          actions={[
            {
              label: `Logout`,
              onClick: () =>
                (window.location.href = apiClient.logoutUri(
                  window.location.origin
                )),
            },
          ]}
        />
      </div>
      <main>
        <Outlet />

        <footer className="mobile-navigation">
          <NavLink className="logo" to="/">
            <Logo />
          </NavLink>
          <Button
            withIcon
            buttonType="secondary"
            onClick={() => setMobileNavOpen(!mobileNavOpen)}
          >
            <Menu />
          </Button>
        </footer>
      </main>
    </div>
  )
}

export default AppAuthWrapper
