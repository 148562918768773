import {
  Table,
  TableCell,
  FinancialText,
  DateText,
  TableColumn,
  NumberText,
} from "@fsg/gui-bits"
import { ReactElement } from "react"
import { useSearchParams } from "react-router-dom"
import DataNotFound from "../../../components/DataNotFound"
import { getFiltersFromHREF } from "../../../helpers/drilldowns"
import { PORecapInterface as DataType, PORecapInterface } from "../../../type"
import { columnLookup } from "../columnLookup"
import './PORecap.scss'

interface Props {
  data: DataType[]
  loading: boolean
}

interface Column {
  property: string
  label: string
  tableCell?: ReactElement
  width?: number
}

const PORecapTable = ({ data, loading }: Props) => {
  const defaultFilters = getFiltersFromHREF(window.location.href)
  const [searchParams] = useSearchParams()
  const sourceColumn = searchParams.get("sourceCol")

  if (!data?.length) return <DataNotFound large />
  const TABLE_DATA = data?.slice()
  
  const columns = [
    {
      property: "vendor",
      label: "Vendor #",
      tableCell: (
        <TableCell
          valueComponent={NumberText}
          getValueProps={() => ({
            decimals: 0,
            commas: 0,
            totalLength: 5,
            padCharacter: "0",
          })}
        />
      ),
      width: 75,
    },
    {
      property: "enc_vennam",
      label: "Vendor label",
      width: 220,
    },
    {
      property: "ponumb",
      label: "PO #",
      tableCell: (
        <TableCell
          drilldown={(datum: PORecapInterface) =>
            `/po-details?po=${datum.ponumb}`
          }
        />
      ),
      width: 110,
    },
    {
      property: "podate",
      label: "PO Date",
      tableCell: <TableCell valueComponent={DateText} />,
      width: 220,
    },
    {
      property: "enc_descri",
      label: "Description",
      width: 200,
    },
    {
      property: "coscod",
      label: "Cost Code",
      tableCell: (
        <TableCell
          valueComponent={NumberText}
          getValueProps={() => ({
            decimals: 0,
            commas: 0,
            totalLength: 3,
            padCharacter: "0",
          })}
        />
      ),
      width: 75,
    },
    {
      property: "catego",
      label: "Cost Category",
      width: 105,
    },
    {
      property: "quoted",
      label: "Quoted",
      width: 65,
    },
    {
      property: "poamnt",
      label: "PO Amt $",
      tableCell: <TableCell valueComponent={FinancialText} />,
      width: 135,
    },
    {
      property: "vpacre",
      label: "VPA Credit $",
      tableCell: <TableCell valueComponent={FinancialText} />,
      width: 135,
    },
    {
      property: "staxpd",
      label: "Sales Tax Paid",
      tableCell: <TableCell valueComponent={FinancialText} />,
      width: 135,
    },
    {
      property: "ponetd",
      label: "Net PO $",
      tableCell: <TableCell valueComponent={FinancialText} />,
      width: 135,
    },
    {
      property: "unpedi",
      label: "Unprocessed Unpaid EDI Inv",
      tableCell: <TableCell valueComponent={FinancialText} />,
      width: 190,
    },
    {
      property: "invamt",
      label: "Invoice Amt",
      tableCell: <TableCell valueComponent={FinancialText} />,
      width: 135,
    },
    {
      property: "pydamt",
      label: "Paid Amount",
      tableCell: <TableCell valueComponent={FinancialText} />,
      width: 135,
    },
    {
      property: "unpcom",
      label: "Unpaid Committed",
      tableCell: <TableCell valueComponent={FinancialText} />,
      width: 135,
    },
    {
      property: "closed",
      label: "Closed",
      width: 65,
    },
  ]

  const hideColumnsBasedOnSource = (
    sourceColumn: string,
    page: string,
    columnsArray: typeof columns
  ) => {
    return columnsArray.filter((column) => {
      const tableColumnShouldHide = columnLookup.cc_detail[sourceColumn][
        page
      ].includes(column.property)

      return !tableColumnShouldHide
    })
  }

  const renderTableColumn = (column: Column) => {
    const { property, label, tableCell, width } = column

    return (
      <TableColumn<DataType>
        key={label}
        label={label}
        width={width || 120}
        identifier={property}
        accessor={(datum: { [key: string]: any }) => `${datum[property] || ""}`}
      >
        {tableCell}
      </TableColumn>
    )
  }

  const shownColumns = sourceColumn
    ? hideColumnsBasedOnSource(sourceColumn, "porecap", columns)
    : columns

  return (
    <div style={{height:"calc(100vh - 155px)"}}>
      <Table<DataType>
        data={TABLE_DATA}
        loading={loading}
        angledHeader
        defaultFilterMode="and"
        defaultFilters={defaultFilters as any[]}
      >
        {shownColumns.map(renderTableColumn)}
      </Table>
    </div>
  );
};

export default PORecapTable
