import {
  DateText,
  FinancialText,
  LoadingSpinner,
  SubHeaderText,
} from "@fsg/gui-bits"
import { useAppSelector } from "../../hooks/reduxHooks"
import { getActiveJobNumber } from "../../Features/JobSelect/jobSearchSlice"
import JobOverview from "./JobOverview"
import "./Summary.scss"
import SimpleTable from "./SimpleTable/SimpleTable"
import JobFinancialSummary from "./JobFinancialSummary"
import useJobSummary from "../../hooks/useJobSummary"
import useJobMeta from "../../hooks/useJobMeta"

const numberParser = (numberString: string) => {
  const trimmed = numberString.slice().trim()
  const matches = trimmed.match(/[-,]/g)
  const formatted = trimmed.replaceAll(/[-,]/g, "")

  return {
    original: numberString,
    formatted,
    isNegative: Boolean(matches?.length && matches.indexOf("-") !== -1),
    number: parseFloat(formatted),
  }
}

const Summary = () => {
  const jobNumber = useAppSelector(getActiveJobNumber)
  const { data, status, error } = useJobSummary(jobNumber)
  const { data: allJobRiskData } = useAppSelector((state) => state.jobRisk)
  const { data: jobMeta} = useJobMeta(jobNumber);

  const currentJobRiskData = allJobRiskData.find(
    (job) => job.jobno === jobNumber
  )!
  const pastDueInvoices = currentJobRiskData?.["ar-over90-amt"].toString() || ``

  if (status === "pending") return <LoadingSpinner />
  if (status === "rejected" || error) return <>Error fetching job summary data</>
  if (status === "idle" || data === undefined) return <></>

  const keyDates = [
    { label: "Contract Date", value: data?.summary1?.[4]?.col2 },
    { label: "Scheduled Start", value: data?.summary1?.[5]?.col2 },
    { label: "Actual Start", value: data?.summary1?.[5]?.col4 },
    { label: "Scheduled Completion", value: data?.summary1?.[6]?.col2 },
    { label: "Actual Completion", value: jobMeta?.act_comp },
    { label: "Last Payroll Post", value: data?.summary1?.[8]?.col2 },
  ]

  const contractRevenue = data.summary2
    .filter((x) => {
      // Pick the objects based on primary key rather than hardcoding index values
      // In case the shape/order of data changes in future
      const categories = [
        "Original Estimate",
        "Approved Changes",
        "Working Changes",
        "Budget Adjustments",
      ]
      return categories.includes(x.colone)
    })
    .map(({ revenu }) => Number(revenu))
    .reduce((a, b) => a + b, 0)

  const amountCollected = data?.summary1?.[0]?.col6
  const unpaidInvoices = data?.summary1?.[1]?.col6
  const unbilledRetainage = data?.summary1?.[2]?.col6
  const overbilledAmount = data?.summary1?.[3]?.col6
  const underbilledAmount = data?.summary1?.[4]?.col6

  const totalBilled = [amountCollected, unpaidInvoices, unbilledRetainage]
    .map((x) => numberParser(x).number)
    .reduce((a, b) => a + b)

  const billingSummary: {
    label: string
    value: string
  }[] = [
    {
      label: "Contract Revenue",
      value: contractRevenue.toString(),
    },
    { label: "Total billed", value: totalBilled.toString() },
    { label: "Amount Collected", value: amountCollected },
    { label: "Unpaid Invoices", value: unpaidInvoices },
    { label: "Past Due Invoices", value: pastDueInvoices },
    { label: "Unbilled Retainage", value: unbilledRetainage },
    { label: "Overbilled Amount", value: overbilledAmount },
    { label: "Underbilled Amount", value: underbilledAmount },
  ]


  const riskReserve: {
    label: string
    value: string
  }[] = [
    { label: "920 OE Budget", value: currentJobRiskData?.oe_920_contract?.toString() || `` },
    { label: "920 Current Amount" , value: currentJobRiskData?.cur_920_current?.toString() || `` },
    { label: "CC Budget Overrun", value: currentJobRiskData?.budget_overrun?.toString() || `` },
    { label: "Actual Risk Reserve", value: currentJobRiskData?.actual_riskres?.toString() || `` },
    { label: "920 Variance", value: currentJobRiskData?.ac_920_variance?.toString() || `` },
  ]

  return (
    <div>
      <JobOverview data={data.summary1} />
      <section className="simple-tables">
        <SimpleTable
          data={keyDates}
          title="Key Dates"
          render={(value: string) => (
            <DateText hideTime={true}>{value}</DateText>
          )}
        />
        <SimpleTable
          data={billingSummary}
          title="Billing Summary"
          render={(value: string) => <FinancialText>{value}</FinancialText>}
        />
        <SimpleTable
          data={riskReserve}
          title="Risk Reserve - 920 Summary"
          render={(value: string) => <FinancialText>{value}</FinancialText>}
        />
      </section>
      <br />
      <SubHeaderText>Job Financial Summary</SubHeaderText>
      <JobFinancialSummary data={data.summary2} />
    </div>
  )
}

export default Summary
