import { getReportsData, Report } from "../../Features/JobReports/jobReportsSlice";
import { useAppSelector } from "../reduxHooks";

const useJobReport = (report: Report) => {
  const allReportsData = useAppSelector(getReportsData);

  return allReportsData[report];
};

export default useJobReport;
