import {
  Table,
  TableColumn,
  DateText,
  FinancialText,
  TableCell,
} from "@fsg/gui-bits"
import DataNotFound from "../../../components/DataNotFound"

import {
  AccountHistoryInterface,
  AccountHistoryInterface as DataType,
} from "../../../type"

interface Props {
  data: DataType[]
  loading: boolean
}
interface Column {
  property: keyof AccountHistoryInterface
  label: string
  width?: number
  tableCell?: JSX.Element
}

const AccountHistoryTable = ({ data, loading }: Props) => {
  if (data?.length) return <DataNotFound large />

  const columns: Column[] = [
    {
      property: `year`,
      label: `Year`,
      width: 70,
    },
    {
      property: `month`,
      label: `Mear`,
      width: 70,
    },
    {
      property: `antcom`,
      label: `Anticipated Comp Date`,
      width: 160,
      tableCell: (
        <TableCell
          valueComponent={DateText}
          getValueProps={() => ({ hideTime: true })}
        />
      ),
    },
    {
      property: `conchg`,
      label: `Cont Chg`,
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      property: `coschg`,
      label: `Cost Chg`,
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      property: `amdcon`,
      label: `Amended Cont.`,
      width: 135,
    },
    {
      property: `amdcos`,
      label: `Amended Est. Cost`,
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      property: `amdpro`,
      label: `Amended Profit`,
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      property: `actcos`,
      label: `Actual Direct Cost`,
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      property: `percom`,
      label: `% Complete`,
      width: 75,
    },
    {
      property: `comcos`,
      label: `Cost to comp`,
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      property: `prdpro`,
      label: `Profit Curr. Period`,
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      property: `tdtpro`,
      label: `Profit Job to Date`,
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      property: `bckpro`,
      label: `Backlog Profit`,
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      property: `biltdt`,
      label: `Billing to Date`,
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      property: `recrev`,
      label: `Revenue Recognized`,
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      property: `undbil`,
      label: `Underbilled`,
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      property: `ovrbil`,
      label: `Overbilled`,
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
  ]

  const TABLE_DATA = data?.slice()

  return (
    <div style={{height:"calc(100vh - 155px)"}}>
      <Table<DataType> data={TABLE_DATA} loading={loading} angledHeader>
        {columns.map(({ property, label, tableCell, width }) => (
          <TableColumn<DataType>
            key={label}
            label={label}
            width={width || 120}
            accessor={(datum: { [key: string]: any }) =>
              `${datum[property] || ""}`
            }
          >
            {tableCell}
          </TableColumn>
        ))}
      </Table>
    </div>
  )
}

export default AccountHistoryTable
