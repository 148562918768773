import {
  DateText,
  FinancialText,
  NumberText,
  Table,
  TableCell,
  TableColumn,
} from "@fsg/gui-bits"
import { ReactElement } from "react"
import { useSearchParams } from "react-router-dom"
import DataNotFound from "../../../components/DataNotFound";
import { getFiltersFromHREF } from "../../../helpers/drilldowns"
import { BudgetInterface as DataType } from "../../../type"
import { columnLookup } from "../columnLookup"

interface Props {
  data: Array<DataType>
  loading: boolean
}

interface Column {
  property: string
  label: string
  tableCell?: ReactElement
  width?: number
}

const BudgetTable = ({ loading, data }: Props) => {
  const defaultFilters = getFiltersFromHREF(window.location.href)
  const [searchParams] = useSearchParams()
  const sourceColumn = searchParams.get("sourceCol")

    if (!data?.length) return <DataNotFound large /> 

  const columns = [
    { property: "entry_type", label: "Entry Type", width: 40 },
    {
      property: "enter_date",
      label: "Enter Date",
      width: 160,
      tableCell: (
        <TableCell
          valueComponent={DateText}
          getValueProps={() => ({ hideTime: true })}
        />
      ),
    },
    {
      property: "fiscal_date",
      label: "Fiscal Date",
      width: 160,
      tableCell: (
        <TableCell
          valueComponent={DateText}
          getValueProps={() => ({ hideTime: true })}
        />
      ),
    },
    { property: "budget_type", label: "Budget Type", width: 40 },
    { property: "bud_desc", label: "Budget Description", width: 300 },
    { property: "cc_type", label: "CC Type", width: 65 },
    { property: "chg_order_no", label: "Change Order Number", width: 45 },
    { property: "cost_code", label: "Cost Code", width: 85 },
    { property: "cc_desc", label: "Cost Code Description", width: 300 },
    {
      property: "direct_hours",
      label: "Direct Hours",
      width: 75,
      tableCell: <TableCell valueComponent={NumberText} />,
    },
    {
      property: "direct_labor",
      label: "Direct Labor",
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    { property: "entered_by", label: "Entered By", width: 60 },
    {
      property: "equipment",
      label: "Equipment",
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      property: "indirect_hours",
      label: "Indirect Hours",
      width: 75,
      tableCell: <TableCell valueComponent={NumberText} />,
    },
    {
      property: "indirect_labor",
      label: "Indirect Labor Dollars",
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      property: "material",
      label: "Material",
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      property: "other",
      label: "Other",
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      property: "prod_quant",
      label: "Prod Quantity",
      width: 75,
      tableCell: (
        <TableCell
          valueComponent={NumberText}
          getValueProps={() => ({ decimals: 0 })}
        />
      ),
    },
    {
      property: "profit",
      label: "Profit",
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      property: "revenue",
      label: "Revenue",
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      property: "sub",
      label: "Sub",
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
  ]

  const hideColumnsBasedOnSource = (
    sourceColumn: string,
    page: string,
    columnsArray: typeof columns
  ) => {
    return columnsArray.filter((column) => {
      const tableColumnShouldHide = columnLookup.cc_detail[sourceColumn][
        page
      ].includes(column.property)

      return !tableColumnShouldHide
    })
  }

  const renderTableColumn = (column: Column) => {
    const { property, label, tableCell, width } = column

    return (
      <TableColumn<DataType>
        key={label}
        label={label}
        width={width || 120}
        identifier={property}
        accessor={(datum: { [key: string]: any }) => `${datum[property] || ""}`}
      >
        {tableCell}
      </TableColumn>
    )
  }

  const shownColumns = sourceColumn
    ? hideColumnsBasedOnSource(sourceColumn, "budgets", columns)
    : columns

  const TABLE_DATA = data?.slice()

  return (
    <div style={{height:"calc(100vh - 155px)"}}>
      <Table<DataType>
        height="82vh"
        loading={loading}
        data={TABLE_DATA}
        angledHeader={true}
        defaultFilterMode="and"
        defaultFilters={defaultFilters as any[]}
      >
        {shownColumns.map(renderTableColumn)}
      </Table>
    </div>
  )
}

export default BudgetTable
