import { useAppSelector } from "../reduxHooks"

interface Options {
  includeClosed: boolean
}

const useJobsSearch = (options?: Options) => {
  const { jobs, status, error } = useAppSelector((state) => state.jobs)

  const { includeClosed } = options || {}

  if (!includeClosed) {
    const openJobs = jobs ? jobs.filter((job) => job.job_status === "Open") : []
    return { data: openJobs, status, error }
  }

  return { data: jobs, status, error }
}

export default useJobsSearch
