import { useEffect, useState } from "react";
import { request } from "../../api";
import { endpoints } from "../../constants/endpoints";
import { JobInfo } from "../../type";

const useJobMeta = (jobNumber: string | undefined) => {
  const [data, setData] = useState<JobInfo>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!jobNumber) return;

    setLoading(true);

    const fetchBudgets = async () => {
      const endpoint = endpoints.info;
      try {
        const data: any = await request({
          path: `${endpoint}?jobno=${jobNumber}`,
        });
        setData(data.info[0] || undefined);
      } catch (e) {
        setError(e as Error);
      }

      setLoading(false);
    };

    fetchBudgets();
  }, [jobNumber]);

  return { data, loading, error };
};
export default useJobMeta;
