import {
  Table,
  TableCell,
  TableColumn,
  FinancialText,
  DateText,
  NumberText,
} from "@fsg/gui-bits"
import { ReactElement } from "react"
import { useSearchParams } from "react-router-dom"
import DataNotFound from "../../../components/DataNotFound"
import { getFiltersFromHREF } from "../../../helpers/drilldowns"
import { CostDetailsInterface } from "../../../type"
import { columnLookup } from "../columnLookup"

type DataType = CostDetailsInterface

interface Props {
  data: DataType[]
  loading: boolean
}

interface Column {
  property: string
  label: string
  tableCell?: ReactElement
  width?: number
}

const CostDetailsTable = ({ data, loading }: Props) => {
  const defaultFilters = getFiltersFromHREF(window.location.href)
  const [searchParams] = useSearchParams()
  const sourceColumn = searchParams.get("sourceCol")

  if (!data?.length) return <DataNotFound large />

  const columns: Column[] = [
    {
      property: "fiscdt",
      label: "Fiscal Period",
      tableCell: (
        <TableCell
          valueComponent={DateText}
          getValueProps={() => ({ hideTime: true })}
        />
      ),
      width: 180,
    },
    {
      property: "costcd",
      label: "CC #",
      width: 75,
    },
    {
      property: "catego",
      label: "Category",
      width: 85,
    },
    {
      property: "latype",
      label: "Labor Type",
    },
    {
      property: "enc_vennam",
      label: "Vendor",
      width: 220,
    },
    {
      property: "ponumb",
      label: "PO #",
      width: 100,
    },
    {
      property: "podate",
      label: "PO Date",
      tableCell: (
        <TableCell
          valueComponent={DateText}
          getValueProps={() => ({ hideTime: true })}
        />
      ),
      width: 180,
    },
    {
      property: "enc_podesc",
      label: "PO Description",
      width: 220,
    },
    {
      property: "trnsdt",
      label: "Trans Date",
      tableCell: (
        <TableCell
          valueComponent={DateText}
          getValueProps={() => ({ hideTime: true })}
        />
      ),
      width: 180,
    },
    {
      property: "enc_descri",
      label: "Description",
      width: 500,
    },
    {
      property: "quanti",
      label: "Quantity",
      tableCell: (
        <TableCell
          valueComponent={NumberText}
          getValueProps={() => ({ commas: false, decimals: 0 })}
        />
      ),
      width: 100,
    },
    {
      property: "netpri",
      label: "Unit Price",
      tableCell: <TableCell valueComponent={FinancialText} />,
      width: 135,
    },
    {
      property: "repcst",
      label: "Repl Cost",
      tableCell: <TableCell valueComponent={FinancialText} />,
      width: 135,
    },
    {
      property: "vpacre",
      label: "VPA Credit",
      tableCell: <TableCell valueComponent={FinancialText} />,
      width: 135,
    },
    {
      property: "netamt",
      label: "Net Amt",
      tableCell: <TableCell valueComponent={FinancialText} />,
      width: 135,
    },
    {
      property: "slstax",
      label: "Sales Tax",
      tableCell: <TableCell valueComponent={FinancialText} />,
      width: 135,
    },
    {
      property: "hours",
      label: "Hours",
      tableCell: (
        <TableCell
          valueComponent={NumberText}
          getValueProps={() => ({ commas: false, decimals: 0 })}
        />
      ),
      width: 100,
    },
    {
      property: "amount",
      label: "Amount",
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      property: "parnum",
      label: "Part #",
    },
    {
      property: "enc_pardes",
      label: "Part Description",
      width: 200,
    },
    {
      property: "apinv",
      label: "AP Invoice #",
      tableCell: (
        <TableCell
          valueComponent={NumberText}
          getValueProps={() => ({ commas: false, decimals: 0 })}
        />
      ),
      width: 100,
    },
    {
      property: "chknum",
      label: "Check #",
      tableCell: (
        <TableCell
          valueComponent={NumberText}
          getValueProps={() => ({ commas: false, decimals: 0 })}
        />
      ),
      width: 100,
    },
    {
      property: "chkdat",
      label: "Check Date",
      tableCell: (
        <TableCell
          valueComponent={DateText}
          getValueProps={() => ({ hideTime: true })}
        />
      ),
      width: 165,
    },
    {
      property: "invamt",
      label: "Invoice Paid Amount",
      tableCell: <TableCell valueComponent={FinancialText} />,
      width: 165,
    },
    {
      property: "vpanum",
      label: "VPA #",
    },
    {
      property: "vpanam",
      label: "VPA label",
    },
    {
      property: "apvnum",
      label: "AP Vendor",
    },
    {
      property: "apvnam",
      label: "AP Vendor label",
    },
    {
      property: "source",
      label: "Source",
      tableCell: <TableCell<DataType> />,
    },
  ]

  const hideColumnsBasedOnSource = (
    sourceColumn: string,
    page: string,
    columnsArray: typeof columns
  ) => {
    return columnsArray.filter((column) => {
      const tableColumnShouldHide = columnLookup.cc_detail[sourceColumn][
        page
      ].includes(column.property)

      return !tableColumnShouldHide
    })
  }

  const renderTableColumn = (column: Column) => {
    const { property, label, tableCell, width } = column

    return (
      <TableColumn<DataType>
        key={label}
        label={label}
        width={width || 120}
        identifier={property}
        accessor={(datum: { [key: string]: any }) => `${datum[property] || ""}`}
      >
        {tableCell}
      </TableColumn>
    )
  }

  const shownColumns = sourceColumn
    ? hideColumnsBasedOnSource(sourceColumn, "costdetails", columns)
    : columns

  const TABLE_DATA = data?.slice()

  return (
    <div style={{height:"calc(100vh - 155px)"}}>
      <Table<DataType>
        data={TABLE_DATA}
        loading={loading}
        defaultFilterMode="and"
        defaultFilters={defaultFilters as any[]}
      >
        {shownColumns.map(renderTableColumn)}
      </Table>
    </div>
  )
}

export default CostDetailsTable
