import { useEffect, useState } from "react"
import { request } from "../../api"
import { endpoints } from "../../constants/endpoints"
import { PODetails } from "../../type"

const usePO = (poNumber: string | undefined) => {
  const [data, setData] = useState<PODetails>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    if (!poNumber) return

    setLoading(true)

    const fetchBudgets = async () => {
      try {
        const data: any = await request({
          path: `${endpoints.pos}?pono=${poNumber}`,
        })
        setData(data[poNumber] || undefined)
      } catch (e) {
        setError(e as Error)
      }

      setLoading(false)
    }

    fetchBudgets()
  }, [poNumber])

  return { data, loading, error }
}
export default usePO
