import { Card, LoadingSpinner, Anchor } from "@fsg/gui-bits"
import Map from "../../../components/Map"
import { getActiveJobNumber } from "../../../Features/JobSelect/jobSearchSlice"
import { useAppSelector } from "../../../hooks/reduxHooks"
import useJobMeta from "../../../hooks/useJobMeta"

interface Props {
  data: any
}

const formatPhoneNumber = (phoneNumberString: string) => {
  if (phoneNumberString.length < 10) return null
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "")
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return "1 (" + match[1] + ") " + match[2] + " - " + match[3]
  }
  return null
}

const JobOverview = ({ data }: Props) => {
  const jobNumber = useAppSelector(getActiveJobNumber)
  const { data: metaData, loading, error } = useJobMeta(jobNumber)

  if (loading) return <LoadingSpinner />
  if (error) return <p>Error getting job info</p>
  if (!metaData) return <p>No job info</p>

  const details = [
    [
      {
        label: "Client",
        details: [data[1].col2],
      },
      {
        label: "Client Contact",
        details: [
          metaData.cust_con_first && metaData.cust_con_last ? (
            <Anchor href={`mailto:no-reply@fsgi.com`}>
              {`${metaData.cust_con_first} ${metaData.cust_con_last}`}
            </Anchor>
          ) : (
            <span className="null-value">Email not provided</span>
          ),
          formatPhoneNumber(metaData.cust_phone) ? (
            <Anchor href={`tel:${metaData.cust_phone}`}>
              {`${formatPhoneNumber(metaData.cust_phone) || ""}`}
            </Anchor>
          ) : (
            <span className="null-value">Phone not provided</span>
          ),
        ],
      },
      {
        label: "Job Address",
        details: [
          metaData.phys_add || (
            <span className="null-value">Address not provided</span>
          ),
        ],
      },
    ],
    [
      {
        label: "Division Assigned",
        details: [
          `${metaData.division} - ${metaData.department} - ${metaData.div_name}`,
        ],
      },
      {
        label: "Project Manager",
        details: [
          `${
            metaData.proj_mgr_name || (
              <span className="null-value">Name not provided</span>
            )
          } - ${
            metaData.proj_mgr_id || (
              <span className="null-value">ID not provided</span>
            )
          }`,
          metaData.proj_mgr_email ? (
            <Anchor href={`mailto:${metaData.proj_mgr_email}`}>
              {metaData.proj_mgr_email}
            </Anchor>
          ) : (
            <span className="null-value">Email not provided</span>
          ),
        ],
      },
      {
        label: "Sales Rep",
        details: [
          <>
            {metaData.sls_rep_name || (
              <span className="null-value">Name not provided</span>
            )}{" "}
            -{" "}
            {metaData.sls_rep_id || (
              <span className="null-value">ID not provided</span>
            )}
          </>,
          metaData.sls_rep_email ? (
            <Anchor href={`mailto:${metaData.sls_rep_email}`}>
              {metaData.sls_rep_email}
            </Anchor>
          ) : (
            <span className="null-value">Email not provided</span>
          ),
        ],
      },
      {
        label: "VP/Branch Manager",
        details: [
          <>
            {metaData.vp_name || (
              <span className="null-value">Name not provided</span>
            )}{" "}
            -{" "}
            {metaData.vp_id || (
              <span className="null-value">ID not provided</span>
            )}
          </>,
        ],
      },
      {
        label: "Estimator",
        details: [
          <>
            {metaData.est_name || (
              <span className="null-value">Name not provided</span>
            )}{" "}
            -{" "}
            {metaData.est_id || (
              <span className="null-value">ID not provided</span>
            )}
          </>,
          metaData.est_email ? (
            <Anchor href={`mailto:${metaData.est_email}`}>
              {metaData.est_email}
            </Anchor>
          ) : (
            <span className="null-value">Email not provided</span>
          ),
        ],
      },
      {
        label: "Foreman",
        details: [
          <>
            {metaData.fore_name || (
              <span className="null-value">Name not provided</span>
            )}{" "}
            -{" "}
            {metaData.fore_id || (
              <span className="null-value">ID not provided</span>
            )}
          </>,
          metaData.fore_email ? (
            <Anchor href={`mailto:${metaData.fore_email}`}>
              {metaData.fore_email}
            </Anchor>
          ) : (
            <span className="null-value">Email not provided</span>
          ),
        ],
      },
    ],
    [
      { label: "Job Type", details: [metaData.type_code] },
      {
        label: "Tax Status",
        details: [
          <Anchor
            useNavLink
            href={`/tax-audit?jobno=${jobNumber}`}
          >{`${metaData.tax_stat}`}</Anchor>,
        ],
      },
      { label: "Bonded", details: [data[4].col8] },
      {
        label: "Liquidated Damages",
        details: [
          `Liq Dmg: ${metaData.liqddt || ""}`,
          `Diq Dmg Dt: ${metaData.liqdmg || ""}`,
        ],
      },

    ],
  ]

  return (
    <Card className="card">
      <div className="job-overview">
        <div className="header">
          <h3>Job Overview</h3>
        </div>
        <div className="summary-grid">
          <div className="detail-sections">
            {details.map((section) => (
              <section>
                {section.map(({ label, details }, key1) => (
                  <div className="summary-item" key={key1}>
                    <p className="summary-item-label">{label}</p>
                    <ul className="summary-item-details ">
                      {details.map((detail, key2) => (
                        <li key={key2}>{detail}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </section>
            ))}
          </div>
          <div className="map">
            {metaData["phys_add"] ? (
              <Map address={metaData["phys_add"]} />
            ) : (
              <p>No address found</p>
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}
export default JobOverview
