import {
  Card,
  LoadingSpinner,
  SelectInput,
  SubHeaderText,
} from "@fsg/gui-bits";
import { useEffect, useState } from "react";
import { request } from "../../../api";
import { JobAnalysisChart } from "./JobChart";
import {
  BaselinePerformanceInterface,
  TableDataAPIResponse,
} from "../../../type";
import "./index.scss";
import { endpoints } from "../../../constants/endpoints";
import { useAppSelector } from "../../../hooks/reduxHooks";
import BaselineTable from "./BaselineTable";
import useJobsApi from "../../../hooks/useJobsApi";
import MaterialBaselineChart from "./MaterialBaselineChart";
import useJobMeta from "../../../hooks/useJobMeta";

type DataType = TableDataAPIResponse<BaselinePerformanceInterface>;

const BaselinePerformancePage = () => {
  const { activeJobNumber } = useAppSelector((state) => state.jobs);
  const {
    data: materialData,
    status: materialStatus,
    error: materialError,
  } = useJobsApi(endpoints.material_baselines, {
    jobno: activeJobNumber,
  });
  const [activeJobData, setActiveJobData] = useState<DataType>();
  const [activeJobChartData, setActiveJobChartData] = useState<any>({
    contract: { hrs: [], amt: [] },
    total: { hrs: [], amt: [] },
    forecast: { hrs: [], amt: [] },
    actual: { hrs: [], amt: [] },
    predicted: { hrs: [], amt: [] },
  });
  const [loadingChartData, setLoadingChartData] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [key, setKey] = useState("hrs");
  const { data: metaData } = useJobMeta(activeJobNumber);
  const completeDate = metaData?.sch_comp;

  useEffect(() => {
    if (!activeJobNumber) return;
    setLoadingChartData(true);

    const fetchJobData = async () => {
      try {
        const data: any = await request({
          path: `${endpoints.labor_baselines}?jobno=${activeJobNumber}`,
        });
        setActiveJobData(data.data);
      } catch (e) {
        setError(e as Error);
      }
    };

    fetchJobData();
    setLoadingChartData(false);
  }, [activeJobNumber]);

  useEffect(() => {
    setLoadingChartData(true);
    if (activeJobData) {
      let newJobData: any = {
        contract: {
          hrs: [],
          amt: [],
        },
        total: {
          hrs: [],
          amt: [],
        },
        forecast: {
          hrs: [],
          amt: [],
        },
        actual: {
          hrs: [],
          amt: [],
        },
        predicted: {
          hrs: [],
          amt: [],
        },
      };

      activeJobData.forEach((d: { [key: string]: any }) => {
        let date = +new Date(d.fiscal_date);
        for (let key of ["hrs", "amt"]) {
          newJobData.contract[key].push({
            date: date,
            value: d[`baseline_contract_${key}`],
          });

          newJobData.total[key].push({
            date: date,
            value: d[`baseline_total_${key}`],
          });

          newJobData.forecast[key].push({
            date: date,
            value: d[`baseline_forecast_${key}`],
          });

          newJobData.actual[key].push({
            date: date,
            value: d[`baseline_actual_${key}`],
          });
          newJobData.predicted[key].push({
            date: date,
            value: d[`baseline_predicted_${key}`],
          });
        }
      });

      setActiveJobChartData(newJobData);
    }
    setLoadingChartData(false);
  }, [activeJobData, key]);

  return (
    <section id="baseline-performance">
      <SubHeaderText>Labor Baseline Performance</SubHeaderText>
      {error ? <div>{error.message}</div> : null}
      {loadingChartData ? (
        <LoadingSpinner />
      ) : (
        <>
          <SelectInput
            className="key-select"
            value={key}
            onChange={(e) => setKey(e.target.value)}
          >
            <option value="hrs">Trend Labor Hours</option>
            <option value="amt">Trend Labor Dollars</option>
          </SelectInput>
          <br />
          {key === "hrs" && (
            <Card className="chart-card">
              <JobAnalysisChart
                data={activeJobChartData}
                label="Direct Labor Hours"
                field="hrs"
                complete={completeDate}
              />
            </Card>
          )}
          {key === "amt" && (
            <Card className="chart-card">
              <JobAnalysisChart
                data={activeJobChartData}
                label="Direct Labor Dollars"
                field="amt"
                complete={completeDate}
              />
            </Card>
          )}
        </>
      )}
      <Card className="chart-card">
        <MaterialBaselineChart
          data={materialData}
          status={materialStatus}
          error={materialError}
          complete={completeDate}
        />
      </Card>
      <BaselineTable data={activeJobData} loading={loadingChartData} />
    </section>
  );
};

export default BaselinePerformancePage;
