import { Outlet } from "react-router-dom"
import { useAppSelector } from "../../hooks/reduxHooks"
import "./index.scss"

import SimpleJobSearch from "../JobSearch/SimpleJobSearch"
import JobNotFound from "../JobNotFound"
import PageHeader from "./PageHeader"
import Loading from "../../components/Loading"

const TablePageLayout = () => {
  const { activeJobNumber, jobs, status } = useAppSelector(
    (state) => state.jobs
  )
  const { data: jobRiskData } = useAppSelector((state) => state.jobRisk)

  let activeJob

  if (!activeJobNumber) return <SimpleJobSearch />
  if (status === "idle") return <></>
  if (status === "pending") return <Loading iconSize="large" />

  const match = jobs?.find((job) => job.jobno === activeJobNumber)
  if (!match) return <JobNotFound />

  activeJob = match

  const activeJobRiskData = jobRiskData.find(
    (job) => job.jobno === activeJobNumber
  )

  return (
    <div className="page-layout">
      <PageHeader activeJob={activeJob} risk={activeJobRiskData?.risk} />
      <div className="outlet">
        <Outlet />
      </div>
    </div>
  )
}

export default TablePageLayout
