import { configureStore } from "@reduxjs/toolkit";
import jobReportsSlice from "./Features/JobReports/jobReportsSlice";
import jobRiskSlice from "./Features/JobRisk/jobRiskSlice";
import jobSearchSlice from "./Features/JobSelect/jobSearchSlice";

export const store = configureStore({
  reducer: {
    jobs: jobSearchSlice,
    jobRisk: jobRiskSlice,
    jobReports: jobReportsSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
