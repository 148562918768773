interface List {
  [key: string]: string[];
}

interface Shape {
  [key: string]: { [key: string]: List };
}

export const columnLookup: Shape = {
  cc_detail: {
    // source column
    dirlab: {
      budgets: [
        // columns to hide in target table
        "revenue",
        "material",
        "equipment",
        "sub",
        "other",
        "indirect_labor",
        "indirect_hours",
        "prod_quant",
      ],
      costdetails: [
        "revenue",
        "material",
        "equipment",
        "sub",
        "other",
        "indirect_labor",
        "indirect_hours",
        "prod_quant",
      ],
      porecap: [
        "revenue",
        "material",
        "equipment",
        "sub",
        "other",
        "indirect_labor",
        "indirect_hours",
        "prod_quant",
      ],
    },
    dirhrs: {
      budgets: [
        "revenue",
        "material",
        "equipment",
        "sub",
        "other",
        "indirect_labor",
        "indirect_hours",
        "prod_quant",
      ],
      costdetails: [
        "revenu",
        "materi",
        "equipm",
        "subcon",
        "other",
        "indlab",
        "indhrs",
        "prodqt",
      ],
      porecap: [
        "revenu",
        "materi",
        "equipm",
        "subcon",
        "other",
        "indlab",
        "indhrs",
        "prodqt",
      ],
    },
    materi: {
      budgets: [
        "revenue",
        "direct_hours",
        "direct_labor",
        "equipment",
        "sub",
        "other",
        "indirect_labor",
        "indirect_hours",
        "prod_quant",
      ],
      costdetails: [
        "revenu",
        "dirhrs",
        "dirlab",
        "equipm",
        "subcon",
        "other",
        "indlab",
        "indhrs",
        "prodqt",
      ],
      porecap: [
        "revenu",
        "dirhrs",
        "dirlab",
        "equipm",
        "subcon",
        "other",
        "indlab",
        "indhrs",
        "prodqt",
      ],
    },
    equipm: {
      budgets: [
        "revenue",
        "direct_hours",
        "direct_labor",
        "material",
        "sub",
        "other",
        "indirect_labor",
        "indirect_hours",
        "prod_quant",
      ],
      costdetails: [
        "revenu",
        "dirhrs",
        "dirlab",
        "materi",
        "subcon",
        "other",
        "indlab",
        "indhrs",
        "prodqt",
      ],
      porecap: [
        "revenu",
        "dirhrs",
        "dirlab",
        "materi",
        "subcon",
        "other",
        "indlab",
        "indhrs",
        "prodqt",
      ],
    },
    subcon: {
      budgets: [
        "revenue",
        "direct_hours",
        "direct_labor",
        "material",
        "equipment",
        "other",
        "indirect_labor",
        "indirect_hours",
        "prod_quant",
      ],
      costdetails: [
        "revenu",
        "dirhrs",
        "dirlab",
        "materi",
        "equipm",
        "other",
        "indlab",
        "indhrs",
        "prodqt",
      ],
      porecap: [
        "revenu",
        "dirhrs",
        "dirlab",
        "materi",
        "equipm",
        "other",
        "indlab",
        "indhrs",
        "prodqt",
      ],
    },
    other: {
      budgets: [
        "revenue",
        "direct_hours",
        "direct_labor",
        "material",
        "equipment",
        "sub",
        "indirect_labor",
        "indirect_hours",
        "prod_quant",
      ],
      costdetails: [
        "revenu",
        "dirhrs",
        "dirlab",
        "materi",
        "equipm",
        "subcon",
        "indlab",
        "indhrs",
        "prodqt",
      ],
      porecap: [
        "revenu",
        "dirhrs",
        "dirlab",
        "materi",
        "equipm",
        "subcon",
        "indlab",
        "indhrs",
        "prodqt",
      ],
    },
    indlab: {
      budgets: [
        "revenue",
        "direct_hours",
        "direct_labor",
        "material",
        "equipment",
        "sub",
        "other",
        "prod_quant",
      ],
      costdetails: [
        "revenu",
        "dirhrs",
        "dirlab",
        "materi",
        "equipm",
        "subcon",
        "other",
        "prodqt",
      ],
      porecap: [
        "revenu",
        "dirhrs",
        "dirlab",
        "materi",
        "equipm",
        "subcon",
        "other",
        "prodqt",
      ],
    },
    indhrs: {
      budgets: [
        "revenue",
        "direct_hours",
        "direct_labor",
        "material",
        "equipment",
        "sub",
        "other",
        "prod_quant",
      ],
      costdetails: [
        "revenu",
        "dirhrs",
        "dirlab",
        "materi",
        "equipm",
        "subcon",
        "other",
        "prodqt",
      ],
      porecap: [
        "revenu",
        "dirhrs",
        "dirlab",
        "materi",
        "equipm",
        "subcon",
        "other",
        "prodqt",
      ],
    },
  },
};
