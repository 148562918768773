import { useEffect, useState } from "react";
import { request } from "../../api";
import { Status } from "../../type";

interface Options {
  jobno?: string;
}

const useJobsApi = (endpoint: string, { jobno }: Options) => {
  const [data, setData] = useState<any>();
  const [status, setStatus] = useState<Status>("idle");
  const [error, setError] = useState<Error | null>(null);
  
  useEffect(() => {
    if (!jobno) setData(undefined)
    setStatus("pending");

    const fetchData = async () => {
      try {
        const data: any = await request({
          path: `${endpoint}?jobno=${jobno}`,
        });
        setData(data);
        setStatus("fulfilled");
      } catch (e) {
        setError(e as Error);
        setStatus("rejected");
      }
    };

    fetchData();
  }, [endpoint, jobno]);

  return { data, status, error };
};
export default useJobsApi;
