import {
  Table,
  TableCell,
  TableColumn,
  FinancialText,
  NumberText,
} from "@fsg/gui-bits"
import DataNotFound from "../../../components/DataNotFound"
import pages from "../../../constants/page"
import { getActiveJobNumber } from "../../../Features/JobSelect/jobSearchSlice"
import {
  buildDrilldownURL,
  getFiltersFromHREF,
  tableFilterOperation,
} from "../../../helpers/drilldowns"
import { useAppSelector } from "../../../hooks/reduxHooks"
import { CCDetailInterface as DataType } from "../../../type"

interface Props {
  data: DataType[]
  loading: boolean
}

const CCDetail = ({ data, loading }: Props) => {
  const jobNumber = useAppSelector(getActiveJobNumber)
  
  if (!data?.length) return <DataNotFound large />
  const TABLE_DATA = data?.slice()

  if (!jobNumber) return <></>

  const defaultFilters = getFiltersFromHREF(window.location.href)

  const typeCodesThatDrilldown = ["rb", "ac", "td", "uc"]

  const getDrilldownFiltersFromRows = (typeCode: string, costCode: any) => {
    const drilldownFilters = []

    switch (typeCode) {
      case "rb":
        drilldownFilters.push({
          columnId: "cost_code",
          operation: tableFilterOperation.TEXT_IS_EXACTLY,
          value: costCode,
        })
        break
      case "ac":
        drilldownFilters.push({
          columnId: "cost_code",
          operation: tableFilterOperation.TEXT_IS_EXACTLY,
          value: costCode,
        })
        drilldownFilters.push({
          columnId: "budget_type",
          operation: tableFilterOperation.TEXT_IS_EXACTLY,
          value: "CO~WC~PM~PL",
        })
        break
      case "td":
        drilldownFilters.push({
          columnId: "costcd",
          operation: tableFilterOperation.TEXT_IS_EXACTLY,
          value: costCode,
        })
        break
      case "uc":
        drilldownFilters.push({
          columnId: "coscod",
          operation: tableFilterOperation.TEXT_IS_EXACTLY,
          value: costCode,
        })
        drilldownFilters.push({
          columnId: "unpcom",
          operation: tableFilterOperation.IS_NOT_EQUAL_TO,
          value: "0.00",
        })
    }

    return drilldownFilters
  }

  const getDrilldownFiltersFromColumns = (
    typeCode: string,
    columnId: string
  ) => {
    const drillDownFilters = []
    switch (columnId) {
      case "dirlab":
        drillDownFilters.push({
          columnId: "direct_labor",
          operation: tableFilterOperation.IS_NOT_EQUAL_TO,
          value: "0.00",
        })
        drillDownFilters.push({
          columnId: "catego",
          operation: tableFilterOperation.TEXT_IS_EXACTLY,
          value: "LA",
        })
        break
      case "dirhrs":
        drillDownFilters.push({
          columnId: "dirhrs",
          operation: tableFilterOperation.IS_NOT_EQUAL_TO,
          value: "0.00",
        })
        drillDownFilters.push({
          columnId: "catego",
          operation: tableFilterOperation.TEXT_IS_EXACTLY,
          value: "LA",
        })
        break
      case "materi":
        drillDownFilters.push({
          columnId:
            typeCode === "rb" || typeCode === "ac" ? "material" : "materi",
          operation: tableFilterOperation.IS_NOT_EQUAL_TO,
          value: "0.00",
        })
        drillDownFilters.push({
          columnId: "catego",
          operation: tableFilterOperation.TEXT_IS_EXACTLY,
          value: "MA",
        })
        break
      case "equipm":
        drillDownFilters.push({
          columnId: "equipm",
          operation: tableFilterOperation.IS_NOT_EQUAL_TO,
          value: "0.00",
        })
        drillDownFilters.push({
          columnId: "catego",
          operation: tableFilterOperation.TEXT_IS_EXACTLY,
          value: "EQ",
        })
        break
      case "subcon":
        drillDownFilters.push({
          columnId: "subcon",
          operation: tableFilterOperation.IS_NOT_EQUAL_TO,
          value: "0.00",
        })
        drillDownFilters.push({
          columnId: "catego",
          operation: tableFilterOperation.TEXT_IS_EXACTLY,
          value: "SU",
        })
        break
      case "other":
        drillDownFilters.push({
          columnId: "other",
          operation: tableFilterOperation.IS_NOT_EQUAL_TO,
          value: "0.00",
        })
        drillDownFilters.push({
          columnId: "catego",
          operation: tableFilterOperation.TEXT_IS_EXACTLY,
          value: "OC",
        })
        break
      case "indlab":
        drillDownFilters.push({
          columnId: "indlab",
          operation: tableFilterOperation.IS_NOT_EQUAL_TO,
          value: "0.00",
        })
        drillDownFilters.push({
          columnId: "catego",
          operation: tableFilterOperation.TEXT_IS_EXACTLY,
          value: "LA",
        })
        break
      case "indhrs":
        drillDownFilters.push({
          columnId: "indhrs",
          operation: tableFilterOperation.IS_NOT_EQUAL_TO,
          value: "0.00",
        })
        drillDownFilters.push({
          columnId: "catego",
          operation: tableFilterOperation.TEXT_IS_EXACTLY,
          value: "OC",
        })
        break
    }

    return drillDownFilters
  }

  const getDrilldownPage = (typeCode: string) => {
    let page = ""
    switch (typeCode) {
      case "rb":
        page = pages.BUDGETS
        break
      case "ac":
        page = pages.BUDGETS
        break
      case "td":
        page = pages.COST_DETAILS
        break
      case "uc":
        page = pages.PO_RECAP
    }
    return page
  }

  const buildDrillDownSettings = (datum: DataType, columnId: string) => {
    const { typecd, costcd } = datum
    const page = getDrilldownPage(typecd)

    const filters = [
      ...getDrilldownFiltersFromRows(typecd, costcd),
      ...getDrilldownFiltersFromColumns(typecd, columnId),
    ]

    return { page, sourceColumn: columnId, filters }
  }

  const columns = [
    { property: "costcd", label: "Code", width: 60, identifier: "costcode" },
    { property: "sort", label: "", width: 40 },
    { property: "enc_descri", label: "Description", width: 205 },
    { property: "budtyp", label: "Type", width: 175 },
    {
      property: "dirlab",
      label: "Direct Labor Cost",
      width: 165,
      tableCell: (
        <TableCell<DataType>
          valueComponent={FinancialText}
          drilldown={(datum) => {
            const shouldDrilldown = typeCodesThatDrilldown.includes(
              datum.typecd
            )
            if (!shouldDrilldown) return undefined

            return buildDrilldownURL(
              jobNumber,
              buildDrillDownSettings(datum, "dirlab")
            )
          }}
        />
      ),
    },
    {
      property: "dirhrs",
      label: "Direct Labor Hours",
      width: 135,
      tableCell: (
        <TableCell<DataType>
          valueComponent={NumberText}
          drilldown={(datum) => {
            const shouldDrilldown = typeCodesThatDrilldown.includes(
              datum.typecd
            )
            if (!shouldDrilldown) return undefined

            return buildDrilldownURL(
              jobNumber,
              buildDrillDownSettings(datum, "dirhrs")
            )
          }}
        />
      ),
    },
    {
      property: "larate",
      label: "Hourly Labor Rate",
      tableCell: <TableCell<DataType> valueComponent={FinancialText} />,
      width: 130,
    },
    {
      property: "materi",
      label: "Material",
      width: 165,
      tableCell: (
        <TableCell<DataType>
          valueComponent={FinancialText}
          drilldown={(datum) => {
            const shouldDrilldown = typeCodesThatDrilldown.includes(
              datum.typecd
            )
            if (!shouldDrilldown) return undefined

            return buildDrilldownURL(
              jobNumber,
              buildDrillDownSettings(datum, "materi")
            )
          }}
        />
      ),
    },
    {
      property: "equipm",
      label: "Equipment",
      width: 165,
      tableCell: (
        <TableCell<DataType>
          valueComponent={FinancialText}
          drilldown={(datum) => {
            const shouldDrilldown = typeCodesThatDrilldown.includes(
              datum.typecd
            )
            if (!shouldDrilldown) return undefined

            return buildDrilldownURL(
              jobNumber,
              buildDrillDownSettings(datum, "equipm")
            )
          }}
        />
      ),
    },
    {
      property: "subcon",
      label: "Sub-Contractor",
      width: 165,
      tableCell: (
        <TableCell<DataType>
          valueComponent={FinancialText}
          drilldown={(datum) => {
            const shouldDrilldown = typeCodesThatDrilldown.includes(
              datum.typecd
            )
            if (!shouldDrilldown) return undefined

            return buildDrilldownURL(
              jobNumber,
              buildDrillDownSettings(datum, "subcon")
            )
          }}
        />
      ),
    },
    {
      property: "other",
      label: "Other",
      tableCell: (
        <TableCell<DataType>
          valueComponent={FinancialText}
          drilldown={(datum) => {
            const shouldDrilldown = typeCodesThatDrilldown.includes(
              datum.typecd
            )
            if (!shouldDrilldown) return undefined

            return buildDrilldownURL(
              jobNumber,
              buildDrillDownSettings(datum, "other")
            )
          }}
        />
      ),
      width: 165,
    },
    {
      property: "indlab",
      label: "Indirect Labor Cost",
      width: 175,
      tableCell: (
        <TableCell<DataType>
          valueComponent={FinancialText}
          drilldown={(datum) => {
            const shouldDrilldown = typeCodesThatDrilldown.includes(
              datum.typecd
            )
            if (!shouldDrilldown) return undefined

            return buildDrilldownURL(
              jobNumber,
              buildDrillDownSettings(datum, "indlab")
            )
          }}
        />
      ),
    },
    {
      property: "indhrs",
      label: "Indirect Labor Hours",
      width: 155,
      tableCell: (
        <TableCell<DataType>
          valueComponent={NumberText}
          drilldown={(datum) => {
            const shouldDrilldown = typeCodesThatDrilldown.includes(
              datum.typecd
            )
            if (!shouldDrilldown) return undefined

            return buildDrilldownURL(
              jobNumber,
              buildDrillDownSettings(datum, "indhrs")
            )
          }}
        />
      ),
    },
    {
      property: "budtot",
      label: "Total",
      tableCell: <TableCell valueComponent={FinancialText} />,
      width: 165,
    },
    {
      property: "pertot",
      label: "Percent",
      tableCell: (
        <TableCell
          valueComponent={NumberText}
          getValueProps={() => ({ percent: true })}
        />
      ),
      width: 100,
    },
  ]

  return (
    <div style={{height:"calc(100vh - 155px)"}}>
      <Table<DataType>
        data={TABLE_DATA}
        loading={loading}
        groupRowsBy={({ costcd }) => costcd}
        defaultFilters={defaultFilters as any[]}
        defaultFilterMode="and"
        defaultSorts={[{ columnId: "costcode", type: "asc"}]}
      >
        {columns.map(({ property, label, width, tableCell, identifier }) => (
          <TableColumn<DataType>
            key={label}
            label={label}
            width={width || 120}
            identifier={identifier || property}
            accessor={(datum: { [key: string]: any }) =>
              `${datum[property] || ""}`
            }
          >
            {tableCell}
          </TableColumn>
        ))}
      </Table>
    </div>
  );
};

export default CCDetail
