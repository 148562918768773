import { LoadingSpinner, SubHeaderText } from "@fsg/gui-bits"
import SOVTable from "./SOVTable"
import useJobReport from "../../../hooks/useJobReport"

const SOV = () => {
  const reportState = useJobReport("sov")
  const { data, error, status } = reportState

  return (
    <div>
      <SubHeaderText>Schedule of Values</SubHeaderText>
      {error && <div className="error">{error.message}</div>}
      {status === "pending" ? (
        <LoadingSpinner />
      ) : (
        <SOVTable loading={status === "pending"} data={data?.lines} />
      )}
    </div>
  )
}

export default SOV
