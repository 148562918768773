import Prompt from "../Prompt"
import './DataNotFound.scss'

interface Props {
  large?: boolean
  headerText?: string
  promptClassName?: string
  wrapperClassName?: string
}

const DataNotFound = ({
  large,
  headerText,
  promptClassName = ``,
  wrapperClassName = ``,
}: Props) => {
  const prompt = (
    <Prompt
      headerText={headerText || `No Data Available`}
      className={`${promptClassName}`}
    >
      <p>We couldn't find the data you requested at this time</p>
    </Prompt>
  )

  return <div className={`default-wrapper ${large ? `large` : ``} ${wrapperClassName}`}>{prompt}</div>
}
export default DataNotFound
