type DataType = {
  label: string
  value: number | string | undefined
}

interface Props {
  data: DataType[]
  title: string
  footerActions?: JSX.Element[]
  render?: (value: any) => JSX.Element
}

const SimpleTable = ({ data, title, footerActions, render }: Props) => {
  return (
    <div className="simple-table">
      <h3>{title}</h3>
      <table>
        <tbody>
        {data.map(({ label, value }) => (
          <tr>
            <td data-label="label">{label}</td>
            <td data-value="value">
              {value
                ? render
                  ? render(value.toString())
                  : value.toString()
                : `-`}
            </td>
          </tr>
        ))}
        </tbody>
      </table>
      {footerActions ? (
        <div className="footer">{footerActions.map((element) => element)}</div>
      ) : null}
    </div>
  )
}
export default SimpleTable
