import {
  FinancialText,
  NumberText,
  Table,
  TableCell,
  TableColumn,
} from "@fsg/gui-bits"
import DataNotFound from "../../../components/DataNotFound"
import { SOVInterface as DataType } from "../../../type"

interface Props {
  data: Array<DataType>
  loading: boolean
}

const SOVTable = ({ loading, data }: Props) => {
  if (!data?.length) return <DataNotFound large />
  const TABLE_DATA = data?.slice()

  return (
    <div style={{height:"calc(100vh - 155px)"}}>
      <Table<DataType>
        loading={loading}
        data={TABLE_DATA}
        getFriendlyIdentifier={({ item_number }) => `${item_number}`}
      >
        <TableColumn<DataType>
          width={55}
          label="Item #"
          accessor={({ item_number }) => `${item_number}`}
        >
          <TableCell
            getValueProps={() => ({
              decimals: 0,
              commas: false,
              totalLength: 3,
              padCharacter: "0",
            })}
            valueComponent={NumberText}
          />
        </TableColumn>
        <TableColumn<DataType>
          width={350}
          label="Description"
          accessor={({ description }) => `${description}`}
        />
        <TableColumn<DataType>
          label="Change Order"
          width={110}
          accessor={({ chg_order_no }) => `${chg_order_no ? "yes" : "no"}`}
        />
        <TableColumn<DataType>
          label="Schedule Value"
          width={135}
          align="right"
          accessor={({ scheduled_value_amt }) => `${scheduled_value_amt}`}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
        <TableColumn<DataType>
          width={135}
          label="Billed to Date"
          align="right"
          accessor={({ billed_to_date_amt }) => `${billed_to_date_amt}`}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
        <TableColumn<DataType>
          width={135}
          label="Stored Material"
          align="right"
          accessor={({ stored_material_amt }) => `${stored_material_amt}`}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
        <TableColumn<DataType>
          width={95}
          label="% Complete"
          align="right"
          accessor={({ complete_per }) => `${complete_per}`}
        >
          <TableCell
            valueComponent={NumberText}
            getValueProps={() => ({ decimals: 2 })}
          />
        </TableColumn>
        <TableColumn<DataType>
          label="Balance to Finish"
          width={135}
          align="right"
          accessor={({ to_finish_amt }) => `${to_finish_amt}`}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
        <TableColumn<DataType>
          label="Amount Retained"
          width={135}
          align="right"
          accessor={({ retained_amt }) => `${retained_amt}`}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
      </Table>
    </div>
  )
}

export default SOVTable
