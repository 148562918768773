import {
  Table,
  TableColumn,
  DateText,
  FinancialText,
  TableCell,
} from "@fsg/gui-bits"
import DataNotFound from "../../../components/DataNotFound";
import { CashFlowInterface } from "../../../type"

type DataType = CashFlowInterface

interface Props {
  data: DataType[]
  loading: boolean
}
const CashFlowTable = ({ data, loading }: Props) => {
  if (!data?.length) return <DataNotFound large />
  const TABLE_DATA = data?.slice()

  return (
    <div style={{height:"calc(100vh - 155px)"}}>
      <Table<DataType> data={TABLE_DATA} loading={loading}>
        <TableColumn<DataType>
          label="Fiscal date"
          width={160}
          accessor={({ fiscdt }) => fiscdt}
        >
          <TableCell
            valueComponent={DateText}
            getValueProps={() => ({ hideTime: true })}
          />
        </TableColumn>
        <TableColumn<DataType>
          width={100}
          label="Fiscal period"
          accessor={({ fiscpd }) => fiscpd}
        >
          <TableCell
            valueComponent={DateText}
            getValueProps={() => ({ hideTime: true })}
          />
        </TableColumn>
        <TableColumn<DataType>
          width={145}
          label="Progress Draw Amt $"
          accessor={({ progdr }) => progdr}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
        <TableColumn<DataType>
          width={135}
          label="Cash Receipts"
          accessor={({ casrec }) => casrec}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
        <TableColumn<DataType>
          width={135}
          label="Unbilled Retainage"
          accessor={({ unbret }) => unbret}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
        <TableColumn<DataType>
          width={135}
          label="Labor Costs"
          accessor={({ labcos }) => labcos}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
        <TableColumn<DataType>
          width={135}
          label="Mateiral Costs"
          accessor={({ matcos }) => matcos}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
        <TableColumn<DataType>
          width={135}
          label="Equipment Costs"
          accessor={({ equcos }) => equcos}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
        <TableColumn<DataType>
          width={135}
          label="Sub-Cont Costs"
          accessor={({ subcos }) => subcos}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
        <TableColumn<DataType>
          width={135}
          label="Other Costs"
          accessor={({ othcos }) => othcos}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
        <TableColumn<DataType>
          width={135}
          label="Total Costs Paid"
          accessor={({ totcos }) => totcos}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
        <TableColumn<DataType>
          width={135}
          label="Period +/- Cash"
          accessor={({ percas }) => percas}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
        <TableColumn<DataType>
          width={135}
          label="JTD +/- Cash"
          accessor={({ jtdcas }) => jtdcas}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
        <TableColumn<DataType>
          width={180}
          label="Monthly Interest Cost at 6%"
          accessor={({ monint }) => monint}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
      </Table>
    </div>
  )
}

export default CashFlowTable
