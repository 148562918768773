import { Anchor, CopyIcon } from "@fsg/gui-bits"
import { ReactComponent as ShareButton } from "../../assets/share-button.svg"
import Flag from "../../components/Flag"
import { Job } from "../../type"

interface Props {
  activeJob: Job
  risk?: string
}
const PageHeader = ({ activeJob, risk }: Props) => {
  return (
    <div className="page-header">
      <CopyIcon
        rounded
        withIcon
        copyText={`${process.env.REACT_APP_MANAGER_APP_URL}/${activeJob.jobno}`}
      >
        <ShareButton />
      </CopyIcon>
      <h1>{activeJob.job_name || ``}</h1>
      <p>{activeJob.jobno}</p>
      {activeJob.job_status === "Closed" ? (
        <Flag type="closed" size="large">
          <span>Closed</span>
        </Flag>
      ) : null}
      {risk !== undefined && risk === "high" ? (
        <Flag type="high" size="large">
          <Anchor
            href={`${process.env.REACT_APP_MANAGER_APP_URL}/${activeJob.jobno}`}
            className="risk-monitor-link"
          >
            High Risk
          </Anchor>
        </Flag>
      ) : null}
    </div>
  )
}
export default PageHeader
