import {
  Table,
  TableCell,
  TableColumn,
  FinancialText,
  DateText,
} from "@fsg/gui-bits"
import DataNotFound from "../../../components/DataNotFound"
import { ManpowerTeamInterface as DataType } from "../../../type"

const columns = [
  { property: "leader", label: "Leader" },
  { property: "empnum", label: "Emp #", width: 75 },
  { property: "firnam", label: "First Name" },
  { property: "lasnam", label: "Last Name" },
  { property: "trade", label: "Trade", width: 50 },
  { property: "desrat", label: "Design E-rating" },
  { property: "applev", label: "Apprentice Level" },
  { property: "wcomcd", label: "WComp Code" },
  { property: "tenure", label: "Years Tenure", width: 75 },
  {
    property: "fwkdat",
    label: "First Work Date",
    tableCell: (
      <TableCell
        valueComponent={DateText}
        getValueProps={() => ({
          hideTime: true,
          month: `2-digit`,
          day: `2-digit`,
          year: `2-digit`,
        })}
      />
    ),
    width: 120,
  },
  {
    property: "lwkdat",
    label: "Last Work Date",
    tableCell: (
      <TableCell
        valueComponent={DateText}
        getValueProps={() => ({
          hideTime: true,
          month: `2-digit`,
          day: `2-digit`,
          year: `2-digit`,
        })}
      />
    ),
    width: 120,
  },
  { property: "paytyp", label: "Pay Type", width: 75 },
  {
    property: "jobrat",
    label: "Job Reg Rate",
    tableCell: <TableCell valueComponent={FinancialText} />,
    width: 100,
  },
  { property: "reghrs", label: "Regular Hours" },
  { property: "ovrhrs", label: "Overtime Hours" },
  { property: "tothrs", label: "Total Hours" },
  {
    property: "regpay",
    label: "Reg Wages",
    tableCell: <TableCell valueComponent={FinancialText} />,
    width: 150,
  },
  {
    property: "ovrpay",
    label: "OT Wages",
    tableCell: <TableCell valueComponent={FinancialText} />,
    width: 150,
  },
  {
    property: "totpay",
    label: "Total Burden Wage",
    tableCell: <TableCell valueComponent={FinancialText} />,
    width: 150,
  },
]

interface Props {
  data: DataType[]
  loading: boolean
}

const TeamTable = ({ data, loading }: Props) => {
  if (!data?.length) return <DataNotFound large />
  const TABLE_DATA = data?.slice()

  return (
    <div style={{height:"calc(100vh - 155px)"}}>
      <Table<DataType> data={TABLE_DATA} loading={loading}>
        {columns.map(({ property, label, tableCell, width }) => (
          <TableColumn<DataType>
            key={label}
            label={label}
            width={width || 120}
            accessor={(datum: { [key: string]: any }) =>
              `${datum[property] || ""}`
            }
          >
            {tableCell}
          </TableColumn>
        ))}
      </Table>
    </div>
  )
}

export default TeamTable
