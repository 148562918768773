import { Button, HeaderText, LinkButton, LoadingSpinner } from "@fsg/gui-bits"
import Prompt from "../../../components/Prompt"
import JobSelect from "../../../Features/JobSelect"
import useJobs from "../../../hooks/useJobs"
import "./SimpleJobSearch.scss"

const SimpleJobSearch = () => {
  const {  status } = useJobs()

  if (status === "pending")
    return (
      <Prompt headerText="Loading jobs...">
        <LoadingSpinner />
      </Prompt>
    )

  return (
    <section id="simple-job-seach-page">
      <div className="wrapper">
        <HeaderText className="header">Select a job to get started</HeaderText>
        <JobSelect />
        <div className="buttons">
          <LinkButton href="/all-jobs" LinkButtonType="secondary">
            Browse All Jobs
          </LinkButton>
          <Button buttonType="secondary">Create a New Job</Button>
        </div>
      </div>
    </section>
  )
}

export default SimpleJobSearch
