import { SubHeaderText } from "@fsg/gui-bits"
import AccountHistoryTable from "./AccountHistoryTable"
import useJobReport from "../../../hooks/useJobReport"

const AccountHistory = () => {
  const reportState = useJobReport("accthist")
  const { data, error, status } = reportState
  console.log(data)

  if (data === undefined) {
    console.error("Data is undefined")
    return <></>
  }

  return (
    <>
      <SubHeaderText>Account History</SubHeaderText>
      {error && <div className="error">{error.message}</div>}
      <AccountHistoryTable data={data?.data} loading={status === "pending"} />
    </>
  )
}

export default AccountHistory
