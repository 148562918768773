import { SubHeaderText } from "@fsg/gui-bits";
import ForecastTable from "./ForecastTable";
import useJobReport from "../../../hooks/useJobReport";

const ManpowerForecast = () => {
  const reportState = useJobReport("man_fore-team");
  const { data, error, status } = reportState;

  return (
    <div>
      <SubHeaderText>Manpower Forecast</SubHeaderText>
      <ForecastTable data={data?.manfore?.data || []} loading={status === "pending"} />
      {error && <div className="error">{error.message}</div>}
    </div>
  );
};

export default ManpowerForecast;
