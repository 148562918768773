import { useSearchParams } from "react-router-dom"
import Prompt from "../../components/Prompt"
import "./JobNotFound.scss"
import JobSelect from "../../Features/JobSelect"
import { LinkButton } from "@fsg/gui-bits"

const JobNotFound = () => {
  const [searchParams] = useSearchParams()
  const jobnoSearchParam = searchParams.get("jobno")

  return (
    <div id="job-not-found-page">
      <Prompt headerText="Job not found!">
        <div className="prompt-wrapper">
          <p>
            <strong>{jobnoSearchParam}</strong> did not return any matches.
          </p>
          <div className="buttons">
            <LinkButton href="/all-jobs" LinkButtonType="secondary">
              Browse All Jobs
            </LinkButton>
            <JobSelect />
          </div>
        </div>
      </Prompt>
    </div>
  )
}
export default JobNotFound
