import { SubHeaderText } from "@fsg/gui-bits";
import CCDetailTable from "./CCDetailTable";
import useJobReport from "../../../hooks/useJobReport";

const CostCodeDetail = () => {
  const reportState = useJobReport("summary-budget-ccdetail");
  const { data, error, status } = reportState;

  return (
    <div>
      <SubHeaderText>Cost Code Detail</SubHeaderText>
      <CCDetailTable data={data?.ccdetail?.data || []} loading={status === "pending"} />
      {error && <div className="error">{error.message}</div>}
    </div>
  );
};

export default CostCodeDetail;
