import {
  createAsyncThunk,
  createSlice,
  PayloadAction,
  SerializedError,
} from "@reduxjs/toolkit"
import { request } from "../../api"
import { endpoints } from "../../constants/endpoints";
import { RootState } from "../../store"
import { Job, Status } from "../../type"

interface JobsState {
  jobs: Job[];
  status: Status;
  error: SerializedError | null;
  activeJobNumber?: string;
  jobSearchQuery?: string;
}

const initialState: JobsState = {
  jobs: [],
  status: "idle",
  error: null,
  activeJobNumber: localStorage.getItem("job-intel/activeJobNumber") || "",
  jobSearchQuery: localStorage.getItem("job-intel/jobSearchQuery") || "",
};

// type JobSearchPayload = string

export const fetchJobs = createAsyncThunk(
  "jobs/fetchJobs",
  async (_, thunkApi) => {
    thunkApi.dispatch(jobSearchSlice.actions.loading())

    try {
      const response = await request({
        path: `${endpoints.jobs}`,
      })
      return response.jobs 
    } catch (error) {
      return error
    }
  }
)

export const jobSearchSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    loading: (state: JobsState) => {
      state.status = "pending";
    },
    setActiveJobNumber(state: JobsState, action: PayloadAction<string>) {
      state.activeJobNumber = action.payload;
      localStorage.setItem("job-intel/activeJobNumber", action.payload);
    },
    setJobSearchQuery(state: JobsState, action: PayloadAction<string>) {
      localStorage.setItem("job-intel/jobSearchQuery", action.payload);
      state.jobSearchQuery = action.payload;
    },
    clearActiveJobNumber(state: JobsState) {
      state.activeJobNumber = undefined;
      localStorage.removeItem("job-intel/activeJobNumber");
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchJobs.pending, (state) => {
        state.status = "pending";
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.status = "fulfilled";
        state.jobs = action.payload;
        state.error = null
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.status = "rejected";
        state.jobs = []
        state.error = action.payload as SerializedError;
      });
  },
});

export const selectAllJobs = (state: RootState) => state.jobs.jobs
export const getJobsStatus = (state: RootState) => state.jobs.status
export const getJobsError = (state: RootState) => state.jobs.error
export const getActiveJobNumber = (state: RootState) =>
  state.jobs.activeJobNumber
export const getSearchInput = (state: RootState) => state.jobs.jobSearchQuery;

export const { loading, setActiveJobNumber, setJobSearchQuery, clearActiveJobNumber } =
  jobSearchSlice.actions;

export default jobSearchSlice.reducer
