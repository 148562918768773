import {
  Button,
  DateText,
  FinancialText,
  LoadingSpinner,
  SubHeaderText,
  Table,
  TableCell,
  TableColumn,
} from "@fsg/gui-bits"

import "./PODetails.scss"
import SimpleTable from "../Summary/SimpleTable/SimpleTable"
import POOverview from "./POOverview"
import { useSearchParams } from "react-router-dom"
import usePO from "../../hooks/usePO"
import { POLine } from "../../type"

const PODetails = () => {
  const [params] = useSearchParams("po");
  const poNumber = params.get("po");

  const { data, loading, error } = usePO(poNumber as string);

  if (loading) return <LoadingSpinner />;
  if (error) return <p>Error getting PO info</p>;
  if (!data) return <p>No PO info</p>;

  const keyDates = [
    {
      label: "PO Date",
      value: data.header.date
    },
    {
      label: "Promise Ship Date",
      value: data.header.promise_dt
    },
    {
      label: "Actual Ship Date",
      value: data.header.ship_dt
    }
  ];

  const totalCosts = [
    {
      label: "NTE",
      value: data.header.po_nte
    },
    {
      label: "Invoiced Amount",
      value: data.header.inv_amt
    },
    // {
    //   label: "Sales Tax",
    //   value: 0
    // },
    // {
    //   label: "Freight",
    //   value: 0
    // },
    // {
    //   label: "Total (Inc Tax & Ship)",
    //   value: data.lines.reduce((acc, line) => acc + line.total, 0)
    // },
    {
      label: "Unpaid Commited",
      value: data.header.unp_com
    }
  ];
  
  const ntePerformance = [
    {
      label: "NTE",
      value: data.header.po_nte
    },
    {
      label: "Ext Line Total",
      value: data.lines.reduce((acc, line) => acc + line.total, 0)
    },
    {
      label: "NTE to Line Variance",
      value: data.header.po_nte - data.lines.reduce((acc, line) => acc + line.total, 0)
    }
  ]
 
  return (
    <div>
    <POOverview data={data.header} />
      <section className="simple-tables">
        <SimpleTable
          data={keyDates}
          title="Key Dates"
          render={(value: string) => (
            <DateText hideTime={true}>{value}</DateText>
          )}
          footerActions={[

          ]}
        />
        <SimpleTable
          data={totalCosts}
          title="Total Costs"
          render={(value: string) => <FinancialText>{value}</FinancialText>}
          footerActions={[
            <Button buttonType="secondary" size="small">
              Diff
            </Button>,
          ]}
        />
        <SimpleTable
          data={ntePerformance}
          title="NTE Performance"
          render={(value: string) => <FinancialText>{value}</FinancialText>}
          footerActions={[
            <Button buttonType="secondary" size="small">
              Sum
            </Button>,
          ]}
        />
      </section>
      <br />
      <SubHeaderText>Line Item Details</SubHeaderText>
      <Table<POLine> data={data.lines}
        getFriendlyIdentifier={(line) => `${line.line}`}
      >
          <TableColumn
            label={"Description"}
            width={250}
            accessor={(row : POLine) => `${row.desc}`} />
          <TableColumn
            label={"Cost Code"}
            width={75}
            accessor={(row : POLine) => `${row.costcd}`} />
          <TableColumn
            label="Category"
            width={75}
            accessor={(row : POLine) => `${row.cat}`} />
          <TableColumn
            label="Ord Qty"
            width={75}
            accessor={(row : POLine) => `${row.ord_qty}`} />
          <TableColumn
            label="Inv Qty"
            width={75}
            accessor={(row : POLine) => `${row.inv_qty}`} />
          <TableColumn
            label="Rec Date"
            width={200}
            accessor={(row : POLine) => `${row.rec_date}`}>
            <TableCell valueComponent={DateText} getValueProps={() => ({ hideTime: true})} />
        </TableColumn>
          <TableColumn
            label="Rec Qty"
            accessor={(row : POLine) => `${row.rec_qty}`} />
          <TableColumn
            label="Unit Cost"
            width={150}
            accessor={(row : POLine) => `${row.unit_cost}`}>
              <TableCell valueComponent={FinancialText} />
          </TableColumn>
          <TableColumn
            label="Total"
            width={150}
            accessor={(row : POLine) => `${row.total}`}>
            <TableCell valueComponent={FinancialText} />
        </TableColumn>
          <TableColumn
            label="Order Total"
            width={150}
            accessor={(row : POLine) => `${row.ord_tot}`}>
            <TableCell valueComponent={FinancialText} />
        </TableColumn>
      </Table>
    </div>
  )
}

export default PODetails
