import { LoadingSpinner, SubHeaderText } from "@fsg/gui-bits"
import useJobReport from "../../../hooks/useJobReport"
import InvoicesTable from "./InvoicesTable"

const InvoicesPage = () => {
  const reportState = useJobReport("invoices")
  const { data, error, status } = reportState

  return (
    <div>
      <SubHeaderText>Invoices</SubHeaderText>
      {error && <div className="error">{error.message}</div>}
      {status === "pending" ? (
        <LoadingSpinner />
      ) : (
        <InvoicesTable
          loading={status === "pending"}
          data={data?.invoices || []}
        />
      )}
    </div>
  )
}

export default InvoicesPage
