import { useEffect, useState } from "react"
import { request } from "../../api"
import { endpoints } from "../../constants/endpoints"
import {
  Status,
  CCDetailInterface,
  Summary1Interface,
  Summary2Interface,
  SummaryBudgetInterface,
} from "../../type"

type DataType = {
  summary1: Array<Summary1Interface>
  summary2: Array<Summary2Interface>
  budget: Array<SummaryBudgetInterface>
  ccdetail: Array<CCDetailInterface>
}

const useJobSummary = (jobNumber: string | undefined) => {
  const [data, setData] = useState<DataType>()
  const [error, setError] = useState<Error | null>(null)
  const [status, setStatus] = useState<Status>("idle")

  useEffect(() => {
    if (!jobNumber) return
    setStatus("pending")

    const fetchData = async () => {
      try {
        const data: any = await request({
          path: `${endpoints.job_summary}?jobno=${jobNumber}`,
        })
        setData(
          {
            summary1: data.summary1.data || [],
            summary2: data.summary2.data || [],
            budget: data.budget.data || [],
            ccdetail: data.ccdetail.data || [],
          } || {}
        )
        setStatus("fulfilled")
      } catch (e) {
        setError(e as Error)
        setStatus("rejected")
      }
    }

    fetchData()
  }, [jobNumber])

  return { data, status, error }
}
export default useJobSummary
