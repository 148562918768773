import "../index.scss";
import BudgetTable from "./BudgetTable";
import { SubHeaderText } from "@fsg/gui-bits";

import useJobReport from "../../../hooks/useJobReport";

const BudgetsPage = () => {
  const reportState = useJobReport("budgets");
  const { data, error, status } = reportState;

  return (
    <section id="budgets">
      <SubHeaderText>Budgets</SubHeaderText>
      {error && <div className="error">{error.message}</div>}
      <BudgetTable
        loading={status === "pending"}
        data={data?.budgets ? data.budgets : []}
      />
    </section>
  );
};

export default BudgetsPage;
