import { Card } from "@fsg/gui-bits";
import Map from "../../../components/Map";
import { JobInfo, TaxAudit } from "../../../type";

interface Props {
  data: TaxAudit;
  metaData: JobInfo
}

const TaxOverview = ({ data, metaData }: Props) => {

  const details = [
    [
      {
        label: "Client",
        details: [metaData.cust_no, metaData.cust_name]
      }
    ],
    [
      {
        label: "Tax Status",
        details: [metaData.tax_stat]
      },
      {
        label: "New Construction",
        details: [metaData.is_new ? "Yes" : "No"]
      },
      {
        label: "Complete",
        details: [metaData.complete ? "Yes" : "No"]
      }
    ],
    [
      {
        label: "State",
        details: [metaData.phys_add]
      },
      {
        label: "Jurisdiction",
        details: [metaData.tax_jur]
      },
      {
        label: "Invoice Type",
        details: [metaData.inv_type, metaData.inv_type_cd]
      }      
    ]
  ];

  return (
    <Card className="card">
      <div className="job-overview">
        <div className="header">
          <h3>Use Tax Audit</h3>
        </div>
        <div className="summary-grid">
          <div className="detail-sections">
            {details.map((section) => (
              <section>
                {section.map(({ label, details }, key1) => (
                  <div className="summary-item" key={key1}>
                    <p className="summary-item-label">{label}</p>
                    <ul className="summary-item-details ">
                      {details.map((detail, key2) => (
                        <li key={key2}>{detail}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </section>
            ))}
          </div>
           <div className="map">
            {metaData["phys_add"] ? (
              <Map address={metaData["phys_add"]} />
            ) : (
              <p>No address found</p>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};
export default TaxOverview;
