import { PropsWithChildren } from "react";
import "./Flag.scss"

type Type = "high" | "moderate" | "low" | "closed"
type Size = "large" | "default" | "small"

interface Props extends PropsWithChildren {
  type?: Type
  size?: Size
}

const Flag = ({ type, size, children }: Props) => {
  return (
    <div className="flag">
      <span className={`${type} ${size}`}>{children}</span>
    </div>
  );
}
export default Flag
