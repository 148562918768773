interface TableFilters {
  columnId: string;
  operation: string;
  value: string | null;
}

export const buildDrilldownURL = (
  jobNumber: string,
  { page, sourceColumn, filters }: { page: string; sourceColumn: string, filters: TableFilters[] }
) => {
  //const { origin } = window.location;

  let href = `/${page}?`;

  const searchParams = new URLSearchParams();

  searchParams.append("jobno", jobNumber);
  searchParams.append("sourceCol", sourceColumn);

  const filtersWithRuleId = filters.map((x, index) => ({
    ...x,
    ruleId: `${index}`,
  }));

  for (const filter of filtersWithRuleId) {
    searchParams.append("ruleId", filter.ruleId);
    searchParams.append("columnId", filter.columnId);
    searchParams.append("operation", filter.operation);
    searchParams.append("value", filter.value !== null ? filter.value : "null");
  }

  href += searchParams;

  return href;
};

export const getFiltersFromHREF = (href: string) => {
  const { searchParams } = new URL(href);

  const ruleIds = searchParams.getAll("ruleId");
  const columnIds = searchParams.getAll("columnId");
  const operations = searchParams.getAll("operation");
  const values = searchParams.getAll("value");

  return ruleIds.map((ruleId) => {
    const index = Number(ruleId);
    return {
      columnId: columnIds[index],
      operation: operations[index],
      value: values[index] !== "null" ? values[index] : null,
    };
  });
};

export const tableFilterOperation = {
  TEXT_CONTAINS: `TEXT_CONTAINS`,
  TEXT_DOES_NOT_CONTAIN: `TEXT_DOES_NOT_CONTAIN`,
  TEXT_STARTS_WITH: `TEXT_STARTS_WITH`,
  TEXT_ENDS_WITH: `TEXT_ENDS_WITH`,
  TEXT_IS_EXACTLY: `TEXT_IS_EXACTLY`,
  DATE_IS: `DATE_IS`,
  DATE_IS_BEFORE: `DATE_IS_BEFORE`,
  DATE_IS_AFTER: `DATE_IS_AFTER`,
  GREATER_THAN: `GREATER_THAN`,
  GREATER_THAN_OR_EQUAL_TO: `GREATER_THAN_OR_EQUAL_TO`,
  LESS_THAN: `LESS_THAN`,
  LESS_THAN_OR_EQUAL_TO: `LESS_THAN_OR_EQUAL_TO`,
  IS_EQUAL_TO: `IS_EQUAL_TO`,
  IS_NOT_EQUAL_TO: `IS_NOT_EQUAL_TO`,
};
