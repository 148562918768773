import { Card } from "@fsg/gui-bits";
import Map from "../../../components/Map";
import { POHeader } from "../../../type";

interface Props {
  data: POHeader;
}
const POOverview = ({ data }: Props) => {

  const address = `${data.vadd1}, ${data.vadd2}, ${data.vadd3}`;

  const details = [
    [
      {
        label: "PO #",
        details:[data.pono]
      },
      {
        label: "Status",
        details: [data.status]
      }
    ],
    [
      {
        label: "Cost Code",
        details: [data.costcd]
      },
      {
        label: "Category",
        details: [data.cat]
      },
      {
        label: "Description",
        details: [data.desc]
      }
    ],
    [
      {
        label: "Vendor",
        details: [data.vend_no, data.vend_name]
      },
      {
        label: "Vendor Address",
        details: [data.vadd1, data.vadd2, data.vadd3]
      }
    ],
    [
      {
        label: "Requested By",
        details: [data.req_by]
      },
      {
        label: "Created By",
        details: [data.created_by]
      }
    ]
  ];
  
  return (
    <Card className="card">
      <div className="job-overview">
        <div className="header">
          <h3>PO Details</h3>
        </div>
        <div className="summary-grid">
          <div className="detail-sections">
            {details.map((section) => (
              <section>
                {section.map(({ label, details }, key1) => (
                  <div className="summary-item" key={key1}>
                    <p className="summary-item-label">{label}</p>
                    <ul className="summary-item-details ">
                      {details.map((detail, key2) => (
                        <li key={key2}>{detail}</li>
                      ))}
                    </ul>
                  </div>
                ))}
              </section>
            ))}
          </div>
          <div className="map">
            {data.vadd3 ? (
              <Map address={address} />
            ) : (
              <p>No address found</p>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};
export default POOverview;
