import { SubHeaderText } from "@fsg/gui-bits";
import TeamTable from "./TeamTable";
import useJobReport from "../../../hooks/useJobReport";

const ManpowerTeam = () => {
  const reportState = useJobReport("man_fore-team");
  const { data, error, status } = reportState;

  return (
    <div>
      <SubHeaderText>Manpower Team Details</SubHeaderText>
      <TeamTable data={data?.manteam?.data || []} loading={status === "pending"} />
      {error && <div className="error">{error.message}</div>}
    </div>
  );
};

export default ManpowerTeam;
