import App from "../App"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import TablePageLayout from "../Pages/JobReporting"
import { routes } from "./routes"
import SimpleJobSearch from "../Pages/JobSearch/SimpleJobSearch"
import FullJobSearch from "../Pages/JobSearch/FullJobSearch"
import JobNotFound from "../Pages/JobNotFound"

function Router() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<SimpleJobSearch />} />
          <Route path="all-jobs" element={<FullJobSearch />} />
          <Route element={<TablePageLayout />}>
            {routes.map((section) =>
              section.routes.map((route, index) => (
                <Route
                  key={index}
                  path={`${route.path}`}
                  element={route.element}
                />
              ))
            )}
          </Route>
          <Route path="job-not-found" element={<JobNotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router
