import { FancySelect } from "@fsg/gui-bits"
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks"
import { setActiveJobNumber, setJobSearchQuery } from "./jobSearchSlice"
import useJobs from "../../hooks/useJobs"
import { useNavigate } from "react-router-dom"
import { debounce } from "lodash"

type OptionsType = {
  label: string
  value: string
}

const JobSelect = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { activeJobNumber, jobSearchQuery: query } = useAppSelector(
    (state) => state.jobs
  )
  const { data: jobs, status, error } = useJobs()

  const handleSetQuery = (query: string) => {
    dispatch(setJobSearchQuery(query))
  }

  const matches = query
    ? jobs.filter(
        (job) =>
          job.job_name.toLowerCase().includes(query.toLowerCase()) ||
          job.jobno.toLowerCase().includes(query.toLowerCase())
      )
    : undefined

  const options = matches?.map((job) => {
    return {
      label: `${job.jobno} - ${job.job_name}`,
      value: job.jobno,
    }
  })

  const selectedOption = options?.find(
    (option) => option.value === activeJobNumber
  )

  const onChange = (option: OptionsType) => {
    if (!option) {
      // clearValue will not workout without this guard
      dispatch(setActiveJobNumber(""))
      return
    }
    dispatch(setActiveJobNumber(option.value))
    navigate(`job-summary?jobno=${option.value}`)
  }

  const debouncedSearch = debounce((inputValue: string) => {
    if (!inputValue) {
      handleSetQuery("")
    }
    if (inputValue.length < 4) return
    handleSetQuery(inputValue)
  }, 300)

  const clearValue = () => {
    handleSetQuery("")
  }

  if (error) return <p className="error">Something went wrong.</p>

  return (
    <FancySelect
      className="job-select"
      value={selectedOption}
      onInputChange={debouncedSearch}
      onChange={onChange}
      clearValue={clearValue}
      selectProps={{
        isClearable: true,
        isLoading: status === "pending",
      }}
      placeholder="Search"
      options={options || []}
    />
  )
}

export default JobSelect
