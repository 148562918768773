import { LoadingSpinner } from "@fsg/gui-bits"
import "./Loading.scss"

interface Props {
  iconOnly?: boolean
  iconSize?: "large" | "medium" | "small"
  iconClassname?: string
}

const Loading = ({ iconOnly, iconSize, iconClassname }: Props) => {
  if (iconOnly) return <LoadingSpinner />

  const spinnerProps: any = {
    size: iconSize || "medium",
  }

  if (iconClassname) {
    spinnerProps["className"] = iconClassname
  }

  return (
    <div className="loading-spinner full">
      <LoadingSpinner {...spinnerProps} />
    </div>
  )
}
export default Loading
