import "./LoginPage.scss"
import Logo from "../../../assets/logo.svg"
import { apiClient } from "../../../api"
import { Button } from "@fsg/gui-bits"

function LoginPage() {
  return (
    <div className="login-page">
      <div className="image-wrapper">
        <img src={Logo} alt="FSG" />
      </div>
      <div>
        <Button
          onClick={() => {
            window.location.href = apiClient.loginUri(
              `${process.env.REACT_APP_JOB_REPORTS_APP_URL}${window.location.pathname}${window.location.search}`
            )
          }}
        >
          Log in
        </Button>
      </div>
    </div>
  )
}

export default LoginPage
