import {
  Table,
  TableCell,
  TableColumn,
  FinancialText,
  DateText,
  NumberText,
} from "@fsg/gui-bits";
import { ReactElement } from "react";
import { useSearchParams } from "react-router-dom";
import DataNotFound from "../../../components/DataNotFound";
import { getFiltersFromHREF } from "../../../helpers/drilldowns";
import { PayrollHistoryInterface } from "../../../type";
import { columnLookup } from "../columnLookup";

type DataType = PayrollHistoryInterface;

interface Props {
  data: DataType[];
  loading: boolean;
}

interface Column {
  property: string;
  label: string;
  tableCell?: ReactElement;
  width?: number;
  accessor?: (datum: DataType) => string;
}

const PayrollHistoryTable = ({ data, loading }: Props) => {
  const defaultFilters = getFiltersFromHREF(window.location.href);
  const [searchParams] = useSearchParams();
  const sourceColumn = searchParams.get("sourceCol");

  if (!data?.length) return <DataNotFound large /> 

  const columns: Column[] = [
    {
      property: "posted",
      label: "Posted",
      accessor: (datum: DataType) => datum.posted ? "Yes" : "No",
      tableCell: (
        <TableCell
          valueComponent={DateText}
          getValueProps={() => ({ hideTime: true })}
        />
      ),
      width: 60,
    },
    {
      property: "employee_type",
      label: "Emp. Type",
      width:90,
    },
    {
      property: "labor_type",
      label: "Labor Type",
      width:70,
    },
    {
      property: "employee_no",
      label: "Emp. #",
      width:50,
    },
    {
      property: "employee_name",
      label: "Emp. Name",
      width: 180,
    },
    {
      property: "period_dt",
      label: "Period Date",      
      tableCell: (
        <TableCell
          valueComponent={DateText}
          getValueProps={() => ({ 
            hideTime: true, 
            month: "2-digit",
            day: "2-digit",
            year: "2-digit"})}
        />
      ),
      width:100
    },
    {
      property: "actual_dt",
      label: "Actual Date",
      tableCell: (
        <TableCell
        valueComponent={DateText}
        getValueProps={() => ({ 
          hideTime: true, 
          month: "2-digit",
          day: "2-digit",
          year: "2-digit"})}
      />
      ),
      width: 100,
    },
    {
      property: "earnings_cat",
      label: "Earnings Cat.",
      width:40,
    },
    {
      property: "trade_code",
      label: "Trade Code",
      width:40,
    },
    {
      property: "cost_code",
      label: "Cost Code",
      width:40,
    },
    {
      property: "regular_rate",
      label: "Reg. Rate",
      tableCell: (
        <TableCell
          valueComponent={FinancialText}
          />
      ),
      width:70
    },
    {
      property: "regular_hrs",
      label: "Reg. Hrs",
      width:50,
      tableCell: (
        <TableCell
          valueComponent={NumberText}
          />
      ),
    },
    {
      property: "regular_amt",
      label: "Reg. Amt",
      tableCell: (
        <TableCell
          valueComponent={FinancialText}
          />
      ),
      width:90
    },
    {
      property: "overtime_hrs",
      label: "OT Hrs",
      width:50,
      tableCell: (
        <TableCell
          valueComponent={NumberText}
          />
      ),
    },
    {
      property: "overtime_amt",
      label: "OT Amt",
      tableCell: (
        <TableCell
          valueComponent={FinancialText}
          />
      ),
      width:90
    },
    {
      property: "doubletime_hrs",
      label: "DT Hrs",
      width:50,
      tableCell: (
        <TableCell
          valueComponent={NumberText}
          />
      ),
    },
    {
      property: "doubletime_amt",
      label: "DT Amt",
      tableCell: (
        <TableCell
          valueComponent={FinancialText}
          />
      ),
      width:90
    },
    {
      property: "benefit_amt",
      label: "Benefit Amt",
      tableCell: (
        <TableCell
          valueComponent={FinancialText}
          />
      ),
      width:90
    },
    {
      property: "tax_amt",
      label: "Tax Amt",
      tableCell: (
        <TableCell
          valueComponent={FinancialText}
          />
      ),
      width:90
    },
    {
      property: "insurance_amt",
      label: "Ins. Amt",
      tableCell: (
        <TableCell
          valueComponent={FinancialText}
          />
      ),
      width:80
    },
    {
      property: "total_hrs",
      label: "Total Hrs",
      width:50,
      tableCell: (
        <TableCell
          valueComponent={NumberText}
          />
      ),
    },
    {
      property: "total_amt",
      label: "Total Amt",
      tableCell: (
        <TableCell
          valueComponent={FinancialText}
          />
      ),
      width:90
    },
    {
      property: "temp_id",
      label: "Temp ID",
      width:80
    },
    {
      property: "temp_agency",
      label: "Temp Agency",
      width:150
    }
  ];

  const hideColumnsBasedOnSource = (
    sourceColumn: string,
    page: string,
    columnsArray: typeof columns
  ) => {
    return columnsArray.filter((column) => {
      const tableColumnShouldHide = columnLookup.cc_detail[sourceColumn][
        page
      ].includes(column.property);

      return !tableColumnShouldHide;
    });
  };

  const renderTableColumn = (column: Column) => {
    const { property, label, tableCell, width, accessor } = column;

    return (
      <TableColumn<DataType>
        key={label}
        label={label}
        width={width || 120}
        identifier={property}
        accessor={(datum: any) => accessor ? accessor(datum) : `${datum[property] || ""}`}
      >
        {tableCell}
      </TableColumn>
    );
  };

  const shownColumns = sourceColumn
    ? hideColumnsBasedOnSource(sourceColumn, "PayrollHistory", columns)
    : columns;

  const TABLE_DATA = data?.slice()

  return (
    <div style={{height:"calc(100vh - 155px)"}}>
      <Table<DataType>
        data={TABLE_DATA}
        loading={loading}
        angledHeader
        defaultFilterMode="and"
        defaultFilters={defaultFilters as any[]}
      >
        {shownColumns.map(renderTableColumn)}
      </Table>
    </div>
  )
};

export default PayrollHistoryTable;
