import { LoadingSpinner, SubHeaderText } from "@fsg/gui-bits";
import useJobReport from "../../../hooks/useJobReport";
import CostDetailsTable from "./CostDetailsTable";

const CostDetails = () => {
  const reportState = useJobReport("costdetails");
  const { data, error, status } = reportState;

  return (
    <div>
      <SubHeaderText>Cost Details</SubHeaderText>
      {error && <div className="error">{error.message}</div>}
      {status !== 'pending' ? (
        <CostDetailsTable data={data?.data} loading={status === "pending"} />
      ) : (
        <LoadingSpinner />
      )}
    </div>
  );
};

export default CostDetails;
