import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  Interaction,
} from "chart.js"
import CrosshairPlugin, { Interpolate } from "chartjs-plugin-crosshair"
import { Line } from "react-chartjs-2"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  CrosshairPlugin
)
Interaction.modes.interpolate = Interpolate;

const numberFormatter = new Intl.NumberFormat("en-US", {
  notation: "compact",
  compactDisplay: "short",
});

export const JobAnalysisChart = ({ data, label, field, complete }) => {

  for(let key in data) data[key][field].sort((a, b) => new Date(a.date) - new Date(b.date));

  let completeValue = data.actual[field].map((x) =>
    new Date(x.date).toLocaleDateString()
  ).indexOf(new Date(complete).toLocaleDateString());

  if (completeValue === -1) {
    for (let i = 0; i < data.actual[field].length; i++) {
      //if complete date falls between 2 dates, set completeValue to the index of the date before complete
      if (new Date(data.actual[field][i]?.date) < new Date(complete) && new Date(data.actual[field][i + 1]?.date) > new Date(complete)) {
        completeValue = i + 0.5;
        break;
      }
    }
    if (completeValue === -1) {
      completeValue = data.actual[field].length - 1;
    }
  }

  const chartData = {
    labels: data.actual[field].map((x) =>
    {
      const d = new Date(x.date);
      return `${d.getMonth() + 1}/${d.getFullYear()}`;
    }
    ),
    datasets: [
      {
        type: "line",
        label: "Actual",
        data: data.actual[field].map((x) => x.value),
        backgroundColor: "rgba(34, 199, 153, 0.5)",
        borderColor: "rgba(34, 199, 153, 1)",
        fill: false,
      },
      {
        type: "line",
        label: "Contract",
        data: data.contract[field].map((x) => x.value),
        backgroundColor: "rgba(240, 69, 51, 0.5)",
        borderColor: "rgba(240, 69, 51, 1)",
        fill: false,
      },
      {
        type: "line",
        label: "Budget Total",
        data: data.total[field].map((x) => x.value),
        backgroundColor: "rgba(0, 98, 255, 0.5)",
        borderColor: "rgba(0, 98, 255, 1)",
        fill: false,
      },
      {
        type: "line",
        label: "PM Forecast",
        data: data.forecast[field].map((x) => x.value),
        backgroundColor: "rgba(255, 151, 74, 0.5)",
        borderColor: "rgba(255, 151, 74, 1)",
        fill: false,
        borderDash: [5],
      },
      {
        type: "line",
        label: "Predicted",
        data: data.predicted[field].map((x) => x.value),
        backgroundColor: "rgba(255, 207, 82, 0.5)",
        borderColor: "rgba(255, 207, 82, 1)",
        fill: false,
        borderDash: [5],
      },
    ],
  }

  return (
    <div style={{ position: "relative", display: "block", width: "100%" }}>
      <Line
        data={chartData}
        options={{
          aspectRatio: 3,
          plugins: {
            title: { text: label, display: true },
            crosshair: { enabled: true },
            tooltip: {
              mode: "interpolate",
              intersect: false,
            },
            annotation: {
              annotations: {
                line1: {
                  drawTime: "afterDatasetsDraw",
                  adjustScaleRange: true,
                  type: 'line',
                  scaleID: "x",
                  value: completeValue,
                  label: {
                    content: `Complete Date ${new Date(complete).toLocaleDateString()}`,
                    enabled: true,
                    display: true,
                  }
                }
              },
            }
          },
          scales: {
            y: {
              ticks: {
                callback: function (value, index, values) {
                  return numberFormatter.format(value);
                }
              }
            }
          }
        }}
        plugins={CrosshairPlugin}
      />
    </div>
  )
}
