import { FinancialText, KPICard, LoadingSpinner, SubHeaderText } from "@fsg/gui-bits"
import PORecapTable from "./PORecapTable"
import useJobReport from "../../../hooks/useJobReport"
import { useAppSelector } from "../../../hooks/reduxHooks"
import { getActiveJobNumber } from "../../../Features/JobSelect/jobSearchSlice"
import useJobSummary from "../../../hooks/useJobSummary"

const PORecap = () => {
  const jobNumber = useAppSelector(getActiveJobNumber)
  const reportState = useJobReport("porecap")
  const { data, error, status } = reportState
  const {
    data: summaryData,
    status: summaryStatus,
    error: summaryError,
  } = useJobSummary(jobNumber!)

  const summary1 = summaryData?.summary1

  const PO_Data = summary1?.[9].col3 + " " + summary1?.[9].col4,
    unprocessedEDIs = summary1?.[5].col8,
    approvedEDIs = summary1?.[6].col8

  const stringExtractor3000 = (PO_dataString: string) => {
    const matches = PO_dataString.match(/[^$0-9]\d+/g)
    return matches ? matches.map((match) => match.trim()) : Array(3).fill(``)
  }
  const [totalNumOfPOs, POsUnder50, avgPOsPerWeek] =
    stringExtractor3000(PO_Data)

  const kpiCards = [
    {
      label: `Total # of PO's`,
      primary: totalNumOfPOs,
    },
    {
      label: `PO's < $50`,
      primary: POsUnder50,
    },
    {
      label: `Avg # PO's/wk`,
      primary: avgPOsPerWeek,
    },
    {
      label: `Unprocessed EDI $`,
      primary: <FinancialText>{`${unprocessedEDIs || ""}`}</FinancialText>,
    },
    {
      label: `Approved EDI AP $: `,
      primary: <FinancialText>{`${approvedEDIs || ""}`}</FinancialText>,
    },
  ]

  const kpiCardUI =
    summaryStatus === "pending" ? (
      <LoadingSpinner />
    ) : summaryError ? (
      <div className="error">{summaryError.message}</div>
    ) : (
      <div className="kpi-cards">{kpiCards.map((props) => <KPICard {...props} />)}</div>
    )

  return (
    <div>
      <SubHeaderText>PO Recap</SubHeaderText>
      {error && <div className="error">{error.message}</div>}
      {kpiCardUI}
      <PORecapTable data={data?.data} loading={status === "pending"} />
    </div>
  )
}

export default PORecap
