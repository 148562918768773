import { ApiClient } from "@fsg/apps-api-client-react"

interface RequestOptions {
  url?: string
  path?: string
  method?: string
  requestOptions?: Partial<RequestInit>
}

export const request = async (options: RequestOptions) => {
  const { url, path, method = `GET`, requestOptions } = options

  if (url && path) {
    throw new Error(`URL and Path are mutually exclusive`)
  }

  if (!url && !path) {
    throw new Error(`Either URL or Path are required`)
  }

  const requestUrl: string = path
    ? `${process.env.REACT_APP_API_GATEWAY_BASE_URL}${path}`
    : url!

  const requestHeaders: HeadersInit = new Headers(requestOptions?.headers)

  const response = await window.fetch(requestUrl, {
    ...requestOptions,
    method,
    headers: requestHeaders,
    credentials: "include",
  })

  if (response.status === 401) {
    const data = await response.json()
    if (data.error === `ServerError`) {
      throw new Error(data.message)
    }

    throw new Error(`Token is invalid`)
  }

  const data = await response.json()

  return data
}

export const apiClient = new ApiClient(
  process.env.REACT_APP_API_GATEWAY_BASE_URL!
)
