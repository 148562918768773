import {
  createAsyncThunk,
  createSlice,
  // current,
  SerializedError,
} from "@reduxjs/toolkit"
import { request } from "../../api"
import { endpoints } from "../../constants/endpoints";
import { RootState } from "../../store"
import { JobRiskInterface, Status } from "../../type"

interface JobsState {
  data: JobRiskInterface[]
  status: Status
  error: SerializedError | null
}


const initialState: JobsState = {
  data: [],
  status: "idle",
  error: null,
}

export const fetchJobRisk = createAsyncThunk(
  "jobs/fetchJobRisk",
  async (_, thunkApi) => {
    thunkApi.dispatch(jobRiskSlice.actions.loading())

    try {
      const response = await request({
        path: `${endpoints.job_risk}`,
      })

      response.jobs = response.jobs.map((job: JobRiskInterface) => ({
        ...job,
        risk:
          parseInt(job["risk-rating"]) >= 50 ||
          job.lahrs > job["contract_labor_hrs"]
            ? "high"
            : parseInt(job["risk-rating"]) >= 30
            ? "moderate"
            : "low",
      }))

      return response.jobs
    } catch (error) {
      return error
    }
  }
)

export const jobRiskSlice = createSlice({
  name: "jobRisk",
  initialState,
  reducers: {
    loading: (state: JobsState) => {
      state.status = "pending"
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchJobRisk.pending, (state) => {
        state.status = "pending"
      })
      .addCase(fetchJobRisk.fulfilled, (state, action) => {
        // console.log(current(state))
        state.status = "fulfilled"
        state.data.push(action.payload)
        state.error = null
      })
      .addCase(fetchJobRisk.rejected, (state, action) => {
        // console.log(current(state))
        state.status = "rejected"
        state.error = action.payload as SerializedError
        state.data = []
      })
  },
})

export const getJobRiskState = (state: RootState) => state.jobRisk
export const getJobRiskData = (state: RootState) => state.jobRisk.data
export const getJobRiskStatus = (state: RootState) => state.jobRisk.status
export const getJobRiskError = (state: RootState) => state.jobRisk.error

export const { loading } = jobRiskSlice.actions

export default jobRiskSlice.reducer
