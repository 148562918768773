import {
  DateText,
  FinancialText,
  Table,
  TableCell,
  TableColumn,
} from "@fsg/gui-bits"
import DataNotFound from "../../../components/DataNotFound"
import { InvoiceInterface as DataType } from "../../../type"

interface Props {
  data: Array<DataType>
  loading: boolean
}

const InvoicesTable = ({ loading, data }: Props) => {
  if (!data?.length) return <DataNotFound large />
  const TABLE_DATA = data?.slice()

  return (
    <div style={{ height: "calc(100vh - 155px)" }}>
      <Table<DataType> loading={loading} data={TABLE_DATA}>
        <TableColumn<DataType>
          width={60}
          label="Cust #"
          accessor={({ cust_no }) => `${cust_no}`}
        />
        <TableColumn<DataType>
          width={200}
          label="Customer Name"
          accessor={({ cust_name }) => `${cust_name}`}
        />
        {/* <TableColumn<DataType>
          width={65}
          label="Inv Type"
          accessor={({ ar_inv_type }) => `${ar_inv_type}`}
        /> */}
        <TableColumn<DataType>
          width={65}
          label="Bill Type"
          accessor={({ bi_inv_type }) => `${bi_inv_type}`}
        />
        <TableColumn<DataType>
          width={40}
          label="Void"
          accessor={({ voided }) => `${voided ? "YES" : "NO"}`}
        >
          <TableCell
            getValueProps={(row: DataType) => ({
              style: { color: row.voided ? "red" : "green" },
            })}
          />
        </TableColumn>
        <TableColumn<DataType>
          width={70}
          label="Invoice #"
          accessor={({ inv_no }) => `${inv_no}`}
        />
        <TableColumn<DataType>
          label="Fiscal Date"
          width={160}
          accessor={({ fiscal_dt }) => `${fiscal_dt}`}
        >
          <TableCell
            valueComponent={DateText}
            getValueProps={() => ({ hideTime: true })}
          />
        </TableColumn>
        <TableColumn<DataType>
          label="Invoice Date"
          width={160}
          accessor={({ inv_dt }) => `${inv_dt}`}
        >
          <TableCell
            valueComponent={DateText}
            getValueProps={() => ({ hideTime: true })}
          />
        </TableColumn>
        <TableColumn<DataType>
          width={135}
          label="Sales Amount"
          accessor={({ sales_amt }) => `${sales_amt}`}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
        <TableColumn<DataType>
          width={135}
          label="Tax Amount"
          accessor={({ tax_amt }) => `${tax_amt}`}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
        {/* <TableColumn<DataType>
          width={145}
          label="Sales Amount w/ Tax"
          accessor={({ sales_amt, tax_amt }) => `${sales_amt + tax_amt}`}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn> */}
        <TableColumn<DataType>
          width={135}
          label="Retained Amount"
          accessor={({ retained_amt }) => `${retained_amt}`}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
        <TableColumn<DataType>
          width={135}
          label="Total Invoince Amt"
          accessor={({ total_amt }) => `${total_amt}`}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
        <TableColumn<DataType>
          width={135}
          label="Amount Paid"
          accessor={({ paid_amt }) => `${paid_amt}`}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
        <TableColumn<DataType>
          label="Period Paid Date"
          width={160}
          accessor={({ pay_period_dt }) => `${pay_period_dt}`}
        >
          <TableCell
            valueComponent={DateText}
            getValueProps={() => ({ hideTime: true })}
          />
        </TableColumn>
        <TableColumn<DataType>
          width={135}
          label="Inv Bal"
          accessor={({ inv_bal_amt }) => `${inv_bal_amt}`}
        >
          <TableCell valueComponent={FinancialText} />
        </TableColumn>
        <TableColumn<DataType>
          width={240}
          label="Inv Desc"
          accessor={({ inv_desc }) => `${inv_desc}`}
        />
      </Table>
    </div>
  )
}

export default InvoicesTable
