import { FC, SVGAttributes } from "react"
import AccountHistory from "../Pages/JobReporting/AccountHistory"
import BaselinePerformance from "../Pages/JobReporting/BaselinePerformance"
import Budgets from "../Pages/JobReporting/Budgets"
import CashFlow from "../Pages/JobReporting/CashFlow"
import CostDetails from "../Pages/JobReporting/CostDetails"
import Invoices from "../Pages/JobReporting/Invoices"
import Manpower from "../Pages/JobReporting/Manpower"
import PORecap from "../Pages/JobReporting/PORecap"
//import ProductionAnalysis from "../Pages/JobReporting/ProductionAnalysis"
import ScheduleOfValues from "../Pages/JobReporting/ScheduleOfValues"
import Summary from "../Pages/Summary"
import { ReactComponent as Dashboard } from "../assets/dashboard.svg"
import ManpowerTeam from "../Pages/JobReporting/Manpower/ManpowerTeam"
import CostCodeDetail from "../Pages/JobReporting/CCDetail"
import TaxAudit from "../Pages/TaxAudit"
import PODetails from "../Pages/PODetails"
import PayrollHistory from "../Pages/JobReporting/PayrollHistory"
import ProfitPerformance from "../Pages/JobReporting/ProfitPerformance"

interface Section {
  name: string
  routes: Route[]
  icon?: FC<SVGAttributes<SVGElement>>
}

interface Route {
  path: string
  element: JSX.Element
  niceText: string
  nav: boolean
}

export const routes: Section[] = [
  {
    name: `Overview`,
    icon: Dashboard,
    routes: [
      {
        path: "job-summary",
        element: <Summary />,
        niceText: "Job Summary",
        nav: true,
      },
      {
        path: "baseline-performance",
        element: <BaselinePerformance />,
        niceText: "Baseline Performance",
        nav: true,
      },
      {
        path: "cost-code-detail",
        element: <CostCodeDetail />,
        niceText: "Cost Code Detail",
        nav: true,
      },
      {
        path: "tax-audit",
        element: <TaxAudit />,
        niceText: "Tax Audit",
        nav: false,
      },
      {
        path: "po-details",
        element: <PODetails />,
        niceText: "PO Details",
        nav: false,
      }
    ],
  },
  {
    name: `Budgets & Costs`,
    routes: [
      {
        path: "budgets",
        element: <Budgets />,
        niceText: "Budget Details",
        nav: true,
      },
      {
        path: "porecap",
        element: <PORecap />,
        niceText: "PO Recap",
        nav: true,
      },
      {
        path: "costdetails",
        element: <CostDetails />,
        niceText: "Cost Details",
        nav: true,
      },
      {
        path: "payroll-history",
        element: <PayrollHistory />,
        niceText: "Payroll History",
        nav: true,
      },
      {
        path: "profit-performance",
        element: <ProfitPerformance />,
        niceText: "Profit Performance",
        nav: true,
      }
    ],
  },
  {
    name: `Manpower`,
    routes: [
      {
        path: "manpower-forecast",
        element: <Manpower />,
        niceText: "Manpower Forecast",
        nav: true,
      },
      {
        path: "manpower-team",
        element: <ManpowerTeam />,
        niceText: "Manpower Team Details",
        nav: true,
      },
    ],
  },
  {
    name: `Billing`,
    routes: [
      {
        path: "invoices",
        element: <Invoices />,
        niceText: "Invoices",
        nav: true,
      },
      {
        path: "sov",
        element: <ScheduleOfValues />,
        niceText: "AIA Billing Report",
        nav: true,
      },
    ],
  },
  {
    name: `Analysis`,
    routes: [
      {
        path: "cashflow",
        element: <CashFlow />,
        niceText: "Cash Flow",
        nav: true,
      },
      {
        path: "accthist",
        element: <AccountHistory />,
        niceText: "Account History",
        nav: true,
      },
    ],
  },
  // {
  //   name: `Other`,
  //   routes: [
  //     {
  //       path: "prodanal",
  //       element: <ProductionAnalysis />,
  //       niceText: "Production Analysis",
  //     },
  //   ]
  // }
]
