import { SubHeaderText } from "@fsg/gui-bits";
import useJobReport from "../../../hooks/useJobReport";
import CashFlowTable from "./CashFlowTable";

const CashFlow = () => {
  const reportState = useJobReport("cashflow");
  const { data, error, status } = reportState;

  return (
    <div>
      <SubHeaderText>Cash Flow</SubHeaderText>
      {error && <div className="error">{error.message}</div>}
      <CashFlowTable data={data?.data || []} loading={status === "pending"} />
    </div>
  );
};

export default CashFlow;
