import {
  SubHeaderText,
  Table,
  TableColumn,
} from "@fsg/gui-bits"
import { useNavigate } from "react-router-dom"
import { setActiveJobNumber } from "../../../Features/JobSelect/jobSearchSlice"
import { useAppDispatch } from "../../../hooks/reduxHooks"
import useJobs from "../../../hooks/useJobs"
import { Job } from "../../../type"

type DataType = Job


const FullJobSearch = () => {
  const { data, status, error } = useJobs({ includeClosed: true })
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  if (!data || error) return <div className="error">An error occurred</div>

  // ! REFACTOR: dispatch logic should be to give setter a job number and the slice should handle all the implementation details.
  const handleRowClick = (job: DataType) => {
    dispatch(setActiveJobNumber(job.jobno))
    navigate(`/job-summary?jobno=${job.jobno}`)
  }

  const TABLE_DATA = data?.slice()

  return (
    <section id="full-job-search-page">
      <div>
        <SubHeaderText>Job Search</SubHeaderText>
      </div>
      <Table<DataType>
        data={TABLE_DATA}
        loading={status === "pending"}
        onRowClick={(datum) => handleRowClick(datum)}
      >
        <TableColumn<DataType>
          label="Job number"
          accessor={(datum) => `${datum.jobno}`}
        />
        <TableColumn<DataType>
          label="Job name"
          width={350}
          accessor={(datum) => `${datum.job_name}`}
        />
        <TableColumn<DataType>
          label="Job status"
          accessor={(datum) => `${datum.job_status}`}
        />
      </Table>
    </section>
  )
}

export default FullJobSearch
