import { Card, SubHeaderText } from "@fsg/gui-bits";
import useJobReport from "../../../hooks/useJobReport";
import JobProfitChart from "./JobProfitChart";

import "./index.scss";

const ProfitPerformance = () => {
  const reportState = useJobReport("profitperformance");
  const { data, error, status } = reportState;
  
  return (
    <div>
      <SubHeaderText>Profit Performance</SubHeaderText>
      {error && <div className="error">{error.message}</div>}
      <Card className="chart-card">
        <JobProfitChart {...data || []} {...status} {...error} />
      </Card>
    </div>
  );
};

export default ProfitPerformance;
