import { request } from "../../api";
import { endpoints } from "../../constants/endpoints";
import { setReportData } from "../../Features/JobReports/jobReportsSlice";
import { store } from "../../store";
import { Status } from "../../type";

export const lookup = [
  {
    endpoint: endpoints.budgets,
    report: "budgets",
  },
  {
    endpoint: endpoints.ar_invoice,
    report: "invoices",
  },
  {
    endpoint: endpoints.sov,
    report: "sov",
  },
  {
    endpoint: endpoints.job_summary,
    report: "summary-budget-ccdetail",
  },
  {
    endpoint: endpoints.team_manpower_forecast,
    report: "man_fore-team",
  },
  {
    endpoint: endpoints.account_history,
    report: "accthist",
  },
  {
    endpoint: endpoints.cashflow,
    report: "cashflow",
  },
  {
    endpoint: endpoints.cost_details,
    report: "costdetails",
  },
  {
    endpoint: endpoints.po_recap,
    report: "porecap",
  },
  {
    endpoint: endpoints.production_analysis,
    report: "prodanal",
  },
  {
    endpoint: endpoints.payroll,
    report: "payroll",
  },
  {
    endpoint: endpoints.profitperformance,
    report: "profitperformance",
  }
];

const runReports = async (jobNumber: string) => {
  // sequential execution of api calls, no concurrency
  for (const obj of lookup) {
    const { endpoint, report } = obj;
    store.dispatch(setReportData({ report: report, status: "pending" }));

    try {
      const data = await request({
        path: `${endpoint}?jobno=${jobNumber}`,
      });

      store.dispatch(
        setReportData({
          report,
          data,
          error: null,
          status: "fulfilled" as Status,
        })
      );
    } catch (error) {
      store.dispatch(
        setReportData({
          report: report,
          data: null,
          error: JSON.stringify(error),
          status: "rejected" as Status,
        })
      );
    }
  }
};

export default runReports;
