import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../store"
import { Status } from "../../type"

type JobDataState = Record<string, any>
const initialState: JobDataState = {
  budgets: {},
  invoices: {},
  sov: {},
  "summary-budget-ccdetail": {},
  "man_fore-team": {},
  accthist: {},
  cashflow: {},
  costdetails: {},
  porecap: {},
  prodanal: {},
  payroll: {},
  profitperformance: {}
}

for (const key in initialState) {
  initialState[key].data = {}
  initialState[key].status = "idle"
  initialState[key].error = null
}

export type Report = keyof typeof initialState

interface ReportPayload {
  report: string
  data?: any
  status?: Status
  error?: any | null
}

export const jobReportsSlice = createSlice({
  name: "jobData",
  initialState,
  reducers: {
    setReportData: (
      state: JobDataState,
      action: PayloadAction<ReportPayload>
    ) => {
      const { report, data, status, error } = action.payload
      state[report].data = data
      state[report].status = status
      state[report].error = error
    },
    clearReports: (state: JobDataState) => {
      for (const report in state) {
        state[report].data = {}
        state[report].status = "idle"
        state[report].error = null
      }
    },
  },
})

export const { setReportData, clearReports } = jobReportsSlice.actions

export const getReportsData = (state: RootState) => state.jobReports

export default jobReportsSlice.reducer
