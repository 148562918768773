const pages = {
  BUDGETS: "budgets",
  INVOICES: "invoices",
  BASELINE_PERFORMANCE: "baseline-performance",
  SCHEDULE_OF_VALUES: "sov",
  SUMMARY: "job-summary",
  CC_DETAIL: "cost-code-detail",
  MANPOWER_FORECAST: "manpower-forecast",
  MANPOWER_TEAM: "manpower-team",
  ACCOUNT_HISTORY: "accthist",
  CASH_FLOW: "cashflow",
  COST_DETAILS: "costdetails",
  PO_RECAP: "porecap",
  PRODUCTION_ANALYSIS: "prodanal",
  METADATA: "info",
};

export default pages