import {
  Table,
  TableCell,
  TableColumn,
  TableColumnGroup,
  FinancialText,
  DateText,
} from "@fsg/gui-bits"
import DataNotFound from "../../../components/DataNotFound";
import { ManpowerForecastInterface as DataType } from "../../../type"

const columnGroups = [
  {
    label: "Forecast",
    columns: [
      {
        property: "for_prddat",
        label: "Prd End Date",
        tableCell: <TableCell valueComponent={DateText} />,
        width: 220,
      },
      { property: "for_mannum", label: "# Men" },
      { property: "for_hrpman", label: "Hrs per Man" },
      {
        property: "for_cstphr",
        label: "Cost per Hr",
        tableCell: <TableCell valueComponent={FinancialText} />,
        width: 150,
      },
      { property: "for_hrspwk", label: "Wkly Hrs" },
      {
        property: "for_cstcom",
        label: "Cost to Complete",
        tableCell: <TableCell valueComponent={FinancialText} />,
        width: 150,
      },
    ],
  },
  {
    label: "Actual",
    columns: [
      { property: "act_mannum", label: "# Men Paid" },
      { property: "act_mgt30h", label: "# Men > 30 Hrs" },
      { property: "act_reghrs", label: "Reg Hrs" },
      { property: "act_ovrhrs", label: "OT Hrs" },
      { property: "act_hrspwk", label: "Weekly Hrs" },
      {
        property: "act_cost",
        label: "Cost",
        tableCell: <TableCell valueComponent={FinancialText} />,
        width: 150,
      },
      {
        property: "act_cstphr",
        label: "Cost per Hr",
        tableCell: <TableCell valueComponent={FinancialText} />,
        width: 150,
      },
    ],
  },
  {
    label: "Variance",
    columns: [
      { property: "var_mannum", label: "Men" },
      {
        property: "var_cost",
        label: "Cost",
        tableCell: <TableCell valueComponent={FinancialText} />,
        width: 150,
      },
    ],
  },
  {
    label: "Running Totals",
    columns: [
      { property: "tot_acthrs", label: "Hours" },
      {
        property: "tot_actcst",
        label: "Cost",
        tableCell: <TableCell valueComponent={FinancialText} />,
        width: 150,
      },
      { property: "bas_adjusc", label: "Scope Adj" },
      { property: "bas_adjupm", label: "PM Adj" },
    ],
  },
  {
    label: "Baselines",
    columns: [
      { property: "bas_hours", label: "Hours" },
      {
        property: "bas_cost",
        label: "Cost",
        tableCell: <TableCell valueComponent={FinancialText} />,
        width: 150,
      },
    ],
  },
]

interface Props {
  data: DataType[]
  loading: boolean
}

const ForecastTable = ({ data, loading }: Props) => {
  if (!data?.length) return <DataNotFound large /> 
  const TABLE_DATA = data?.slice()

  return (
    <div style={{height:"calc(100vh - 155px)"}}>
      <Table<DataType> data={TABLE_DATA} loading={loading}>
        {columnGroups.map(({ label: columnGroupLabel, columns }) => {
          return (
            <TableColumnGroup label={columnGroupLabel} key={columnGroupLabel}>
              {columns.map(({ property, label, tableCell, width }) => (
                <TableColumn<DataType>
                  key={label}
                  label={label}
                  width={width || 135}
                  accessor={(datum: { [key: string]: any }) =>
                    `${datum[property] || ""}`
                  }
                >
                  {tableCell}
                </TableColumn>
              ))}
            </TableColumnGroup>
          )
        })}
      </Table>
    </div>
  )
}

export default ForecastTable
