import { useEffect, useState } from "react"
import { request } from "../../api"
import { endpoints } from "../../constants/endpoints"
import { TaxAudit } from "../../type"

const useTaxAudit = (jobNumber: string | undefined) => {
  const [data, setData] = useState<TaxAudit>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)

  useEffect(() => {
    if (!jobNumber) return

    setLoading(true)

    const fetchTaxes = async () => {
      try {
        const data: any = await request({
          path: `${endpoints.tax_audit}?jobno=${jobNumber}`,
        })
        setData(data["data"][0] || undefined)
      } catch (e) {
        setError(e as Error)
      }

      setLoading(false)
    }

    fetchTaxes()
  }, [jobNumber])

  return { data, loading, error }
}
export default useTaxAudit
