import {
  DateText,
  Table,
  TableCell,
  TableColumn,
  FinancialText,
  NumberText,
} from "@fsg/gui-bits"
import DataNotFound from "../../../components/DataNotFound";
import { BaselinePerformanceInterface as DataType } from "../../../type"

interface Props {
  data?: DataType[]
  loading: boolean
}

const BaselineTable = ({ data, loading }: Props) => {
    if (!data?.length) return <DataNotFound large /> 

  const columns = [
    {
      label: "Fiscal Period",
      property: "fiscal_date",
      width: 180,
      tableCell: (
        <TableCell
          valueComponent={DateText}
          getValueProps={() => ({ hideTime: true })}
        />
      ),
    },
    {
      label: "OE",
      property: "oe_hrs",
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      label: "CO",
      property: "co_hrs",
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      label: "CH",
      property: "ch_hrs",
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      label: "PM",
      property: "pm_hrs",
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      label: "WC",
      property: "wc_hrs",
      width: 135,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      label: "Forecast Hours",
      property: "forecast_hrs",
      width: 135,
      tableCell: <TableCell valueComponent={NumberText} />,
    },
    {
      label: "Forecast Baseline",
      property: "baseline_forecast_hrs",
      width: 135,
      tableCell: <TableCell valueComponent={NumberText} />,
    },
    {
      label: "Total Baseline",
      property: "baseline_total_hrs",
      width: 135,
      tableCell: <TableCell valueComponent={NumberText} />,
    },
    {
      label: "Contract Baseline",
      property: "baseline_contract_hrs",
      width: 135,
      tableCell: <TableCell valueComponent={NumberText} />,
    },
    {
      label: "Actual Hours",
      property: "baseline_actual_hrs",
      width: 135,
      tableCell: <TableCell valueComponent={NumberText} />,
    },
  ]

  const TABLE_DATA = data?.slice()

  return (
    <Table<DataType> data={TABLE_DATA || []} loading={loading}>
      {columns.map(({ property, label, tableCell, width }) => (
        <TableColumn<DataType>
          key={label}
          label={label}
          width={width || 150}
          accessor={(datum: { [key: string]: any }) =>
            `${datum[property] || ""}`
          }
        >
          {tableCell}
        </TableColumn>
      ))}
    </Table>
  )
}
export default BaselineTable
