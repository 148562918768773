import { LoadingSpinner, SubHeaderText } from "@fsg/gui-bits";
import useJobReport from "../../../hooks/useJobReport";
import PayrollHistoryTable from "./PayrollHistoryTable";

const PayrollHistory = () => {
  const reportState = useJobReport("payroll");
  const { data, error, status } = reportState;

  return (
    <>
      <SubHeaderText>Payroll History</SubHeaderText>
      {error && <div className="error">{error.message}</div>}
      {status !== 'pending' ? (
        <PayrollHistoryTable data={data?.data} loading={status === "pending"} />
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default PayrollHistory;
