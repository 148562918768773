import Map, { Marker } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import useGeocoder from "../../hooks/useGeocoder";
import { LoadingSpinner } from "@fsg/gui-bits";
import { ReactComponent as MapFallback } from "../../assets/map-fallback.svg";

interface Props {
  address: string;
}

const Mapbox = ({ address }: Props) => {
  const { position: coordinates, status, error } = useGeocoder(address);

  if (status === "pending") return <LoadingSpinner />;
  if (!coordinates || status === "rejected" || error)
    return (
      <div className="map-fallback">
        <MapFallback />
      </div>
    );

  const [latitude, longitude] = coordinates;
  const tileset = `satellite-v9`;

  return (
    <Map
      reuseMaps
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
      initialViewState={{
        longitude,
        latitude,
        zoom: 14,
      }}
      mapStyle={`mapbox://styles/mapbox/${tileset}`}
    >
      <Marker latitude={latitude} longitude={longitude} anchor="bottom" />
    </Map>
  );
};

export default Mapbox;
