import { Table, TableColumn, FinancialText, TableCell, NumberText } from "@fsg/gui-bits"

interface Props {
  data: Summary2Interface[]
}

interface Summary2Interface {
  row_ext: string
  costyp_ext: string
  colone: string
  revenu: string
  dirlab: string
  dirhrs: string
  comlra: string
  materi: string
  equipm: string
  subcon: string
  other: string
  indlab: string
  indhrs: string
  tldcos: string
  gropro: string
  "gross%": string
}

type DataType = Summary2Interface

interface Column {
  label: string
  width?: number
  property: keyof DataType
  tableCell?: JSX.Element
}

const JobFinancialSummary = ({ data }: Props) => {
  const columns: Column[] = [
    {
      label: `Contract Revenue`,
      property: `revenu`,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      label: `Direct Labor`,
      property: `dirlab`,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      label: `Blended Labor Rate`,
      property: `comlra`, // verify
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      label: `Material`,
      property: `materi`,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      label: `Equipment`,
      property: `equipm`,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      label: `Sub Cont`,
      property: `subcon`,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      label: `Other`,
      property: `other`,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      label: `Indirect Labor $`,
      property: `indlab`,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      label: `Total`,
      property: `tldcos`,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      label: `Gross Profit`,
      property: `gropro`,
      tableCell: <TableCell valueComponent={FinancialText} />,
    },
    {
      label: `GP%`,
      property: `gross%`,
      tableCell: <TableCell valueComponent={NumberText} getValueProps={() => ({ percent: true}) } />,
    },
  ]

  return (
    <Table<DataType> data={data}
      getFriendlyIdentifier={(row) => `${row.colone}`}
      identifierWidth={200}
    >
      {columns.map(({ property, label, tableCell, width }) => (
        <TableColumn<DataType>
          key={label}
          label={label}
          width={width || 150}
          accessor={(datum: { [key: string]: any }) =>
            `${datum[property] || ""}`
          }
        >
          {tableCell}
        </TableColumn>
      ))}
    </Table>
  )
}

export default JobFinancialSummary
