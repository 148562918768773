import { PropsWithChildren } from "react"
import "./Prompt.scss"

interface Props extends PropsWithChildren {
  headerText: string
  className?: string
}

const Prompt = ({ headerText, className = ``, children }: Props) => {
  return (
    <section className={`prompt ${className}`}>
      <div className="wrapper">
        <h2 className="header">{headerText}</h2>
        {children}
      </div>
    </section>
  )
}
export default Prompt
