import {
  Button,
  FinancialText,
  LoadingSpinner,
  SubHeaderText,
} from "@fsg/gui-bits"
import { useAppSelector } from "../../hooks/reduxHooks"
import { getActiveJobNumber } from "../../Features/JobSelect/jobSearchSlice"
import "./TaxAudit.scss"
import SimpleTable from "../Summary/SimpleTable/SimpleTable"
import TaxOverview from "./TaxOverview"
import useTaxAudit from "../../hooks/useTaxAudit"
import useJobMeta from "../../hooks/useJobMeta"

const TaxAudit = () => {
  const jobNumber = useAppSelector(getActiveJobNumber)

  const { data, loading, error } = useTaxAudit(jobNumber);
  const { data: metaData, loading : metaLoading, error : metaError } = useJobMeta(jobNumber);

  if (loading || metaLoading) return <LoadingSpinner />;
  if (error || metaError) return <p>Error getting Tax Info</p>;
  if (!data) return <p>No Tax Info</p>;


  const table1 = [
    {
      label: "Contract Amount",
      value: data.contract_amt
    },
    {
      label: "Net Amount Billed",
      value: data.billed_net
    },
    {
      label: "Sales Tax Billed",
      value: data.billed_sls_tax
    },
    {
      label: "Tax Rate",
      value: data.tax_rate
    }
  ];

  const table2 = [
    {
      label: "Material Budget",
      value: data.mat_budget
    },
    {
      label: "Sales Tax Paid on Materials",
      value: data.tax_paid_ma
    },
    {
      label: "Net Material Cost",
      value: data.mat_cost_net
    },
  ];

  const table3 = [
    {
      label: "Use Tax Budgeted - CC 1000",
      value: data.use_tax_budget
    },
    {
      label: "Use Tax Costs - CC 1000",
      value: data.use_tax_costed
    },
    {
      label: "Tax Accrued",
      value: data.tax_amt
    },
    {
      label: "Estimated Tax Liability for Job as Budgeted",
      value: data.est_tax_amt
    },
    {
      label: "Accrued Use Tax Liability Variance from Budget",
      value: data.use_tax_diff
    },
    {
      label: "Estimated Use Tax Liability Variance from Budget",
      value: data.est_use_tax_diff
    }
  ];

  return (
    <div>
      <TaxOverview data={data} metaData={metaData!} />

      <SubHeaderText>Tax Details</SubHeaderText>  

      <section className="simple-tables">
        <SimpleTable
          data={table1}
          title=""
          render={(value: string) => (
            <FinancialText>{value}</FinancialText>
          )}
          footerActions={[
            <Button buttonType="secondary" size="small">
              Avg
            </Button>,
          ]}
        />
        <SimpleTable
          data={table2}
          title=""
          render={(value: string) => <FinancialText>{value}</FinancialText>}
          footerActions={[
            <Button buttonType="secondary" size="small">
              Diff
            </Button>,
          ]}
        />
        <SimpleTable
          data={table3}
          title=""
          render={(value: string) => <FinancialText>{value}</FinancialText>}
          footerActions={[
            <Button buttonType="secondary" size="small">
              Diff
            </Button>,
          ]}
        />
      </section>
    </div>
  )
}

export default TaxAudit
